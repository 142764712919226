import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getOrientation = async (token, orientationId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/orientation/${orientationId}/`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useOrientation(orientationId) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['orientation', orientationId], () => getOrientation(token, orientationId));
}