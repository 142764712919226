import React from 'react'
import Badge from './Badge';
import { INTEREST_STATUS } from '../data/InterestStatus';
import { useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import dummyImage from "../data/dummyImage.jpeg"
import moment from 'moment';
import { PROVIDER_TYPES } from '../data/ProviderTypes';

const InterestTableRows = ({ interestList }) => {
  const navigate = useNavigate();
  return (
    <tr className="border-b border-grey hover:cursor-grab hover:bg-violet-100 odd:bg-white even:bg-slate-50"
      onClick={() => navigate(`/listing/${interestList.listing}/interests/${interestList.id}/profile/${interestList.profile_license.profile.id}`,
        { state: { status: interestList.status } }
      )}>
      <td className="px-1 py-1 whitespace-nowrap text-gray-900 flex flex-row ">
        <div className="py-2 px-3 mt-2">
          {interestList.profile_license.profile.image.length > 1
            ? <img src={interestList.profile_license.profile.image} className="rounded-full w-12 h-12 tabletx:hidden grow-0" alt={interestList.profile_license.profile.name} />
            : <img src={dummyImage} className="rounded-full w-12 h-12 tabletx:hidden grow-0" alt={interestList.profile_license.profile.name} />}
        </div>
        <ul className="flex flex-col">
          <li className='mt-3 font-bold text-sm'>{interestList.profile_license.profile.name}</li>
          <li className='mt-2 text-xs flex flex-row space-x-2'>
            <GrLocation className='w-3.5 h-3.5' />
            <div>{`${interestList.profile_license.profile.areas.length} ${interestList.profile_license.profile.areas.length > 1 ? "areas" : "area"}`}</div>
          </li>
        </ul>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900"> {moment(interestList.created_on).fromNow()}</td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900">
        <Badge className="bg-shadowBlue text-navyBlue">{PROVIDER_TYPES[interestList.profile_license.type]}</Badge></td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900"> {interestList.listing}</td>
      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
        <Badge className={INTEREST_STATUS[interestList.status].css}>{INTEREST_STATUS[interestList.status].value}</Badge>
      </td>
    </tr>

  )
}

export default InterestTableRows