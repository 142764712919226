import React from 'react'
import Timeline from './Timeline'

const InterestLogsCard = ({logs}) => {
  return (
    <>
    <h2 className='text-lg font-bold mb-3'>Timeline</h2>
    <Timeline logs={logs}/>
    </>
  )
}

export default InterestLogsCard