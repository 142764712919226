import React from 'react'
import Button from './Button'

const Modal = (props) => {
  return (
    <div id="defaultModal" aria-hidden="true" className=" bg-opacity-25 backdrop-brightness-50 overflow-y-auto overflow-x-hidden flex items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full">
      <div className={`relative p-4 w-full ${props.width ? props.width : 'max-w-2xl'} max-h-full overflow-y-auto`}>
        <div className="relative bg-gray-50 rounded-lg shadow ">
          <div className="flex justify-between items-start p-4 xs:py-2 rounded-t border-b ">
            <h3 className="text-xl xs:text-lg font-semibold text-gray-900 ">
              {props.title}
            </h3>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={() => props.onClose()}  >
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form onSubmit={props.onSubmit}>
            {props.children}
            {!props.hideSubmitButton && <div className="flex items-center p-6 space-x-2 text-base xs:text-sm xs:py-3 rounded-b border-t ">
              <Button className="text-gray-500 bg-white hover:bg-gray-100 py-1.5 border border-gray-200  hover:text-gray-900"
                onClick={() => props.onClose()}>
                {(props.buttonName.toLowerCase().includes("delete")
                  || props.buttonName.toLowerCase().includes("cancel")) ? "No" : "Cancel"}</Button>
              <Button className={`py-1.5 ml-1 ${(props.buttonName.toLowerCase().includes("delete")
                || props.buttonName.toLowerCase().includes("cancel")) ? 'bg-red-600 hover:bg-red-800' : ''}`} type="submit">{props.buttonName}</Button>
            </div>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Modal