import dayjs from "dayjs";

export function getMonth(month = dayjs().month()) {
  let count = 5;
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  let date = year+"-"+(month+1).toString()+"-01";
  let noOfDays = dayjs(date).daysInMonth()
  if((firstDayOfTheMonth === 6 && noOfDays >= 30) || (firstDayOfTheMonth === 5 && noOfDays > 30))
  count = 6
  const daysMatrix = new Array(count).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export function getProviderValue(providerList, providerType) {
  const value = providerList.map((list) => (list.key === providerType && list.value))
  return value;
}

export function getLabelFromValue(List, data) {
  const label = List.map((list) => (list.value === data && list.label))
  return label;
}

export function getCssFromValue(List, data) {
  const css = List.map((list) => (list.value === data && list.css))
  return css;
}