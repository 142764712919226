import React, { useState } from "react";
import Layout from '../components/Layout';
import AssignmentDetailActionCard from '../components/AssignmentDetailActionCard';
import AssignmentDetailCard from '../components/AssignmentDetailCard';
import { useParams } from 'react-router';
import useAssignment from '../api/hooks/useAssignment';
import TopBar from "../components/TopBar";
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import AssignmentLogsCard from '../components/AssignmentLogsCard';
import useAssignmentLogs from '../api/hooks/useAssignmentLogs';
import BackdropLoading from "../components/BackdropLoading"
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Title from "../components/Title";
import withPermissionRequired from "../services/auth0";

const Assignment = () => {
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const { assignmentId } = useParams();
  const { isLoading,
    isError,
    error,
    data: assignment } = useAssignment(assignmentId);
  const { isLogsLoading,
    isError: isLogsError,
    error: logsError,
    data: logs } = useAssignmentLogs(assignmentId);
  useEffect(() => {
    if (isLogsError && logsError.request.status !== 403 && logsError.request.status !== 404) {
      toast.warn(<ToastNotification message={`Couldn't show timeline: ${logsError.message}`} status="warn" />);
    }
  }, [isLogsError, logsError]);
  let content = ''
  if (isLoading || isLogsLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (assignment) {
    content = <>
      <Title title={`${assignment.profile.name}'s Assignment`} />
      {/* <div className="flex flex-row justify-center gap-x-10 tablet:flex-col-reverse tablet:gap-y-10 "> */}
      <div className="flex lg:flex-row md:w-full w-full lg:justify-between gap-y-10 md:flex-col-reverse flex-col-reverse justify-center">
        <div className='basis-[67%]  flex flex-col gap-y-8'>
          <AssignmentDetailCard
            assignment={assignment}
            setInvoiceLoading={setInvoiceLoading}
          />
          {logs &&
            <AssignmentLogsCard
              logs={logs}
            />}
        </div>
        <div className='basis-[30%]  h-full'>
          <AssignmentDetailActionCard
            assignment={assignment}
          />
        </div>
      </div>
    </>
  }
  return (
    <Layout>
      <TopBar />
      {content}
      {invoiceLoading && <BackdropLoading />}
    </Layout>
  )
}

export default withPermissionRequired(Assignment, ["detail:assignment-log", "detail:assignment", "create:extension", "update:assignment"], true);