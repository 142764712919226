import React, { useState } from "react";
import { PROVIDER_TYPES } from '../data/ProviderTypes'
import Accordion from './Accordion';
import DocumentsViewModal from './DocumentsViewModal';
import AccordionDocumentData from "./AccordionDocumentData";
import Badge from "./Badge";
import { PROFILE_LICENSE_STATUS } from "../data/ProfileLicenseStatus";
import { FiExternalLink } from "react-icons/fi";
import PopOver from "./PopOver";

const DocumentsCard = ({ licenses }) => {
  const [file, setFile] = useState(null);
  const [viewDocumentModal, setViewDocumentModal] = useState(false);

  const onImageView = (file) => {
    setFile(file);
    setViewDocumentModal(true);
  }
  const onClose = () => {
    setViewDocumentModal(false);
  }

  return (
    <>
      {licenses.map((license) => (<>
        <div className="flex flex-row gap-y-2 flex-wrap gap-x-4">
          <h2 className='text-base font-bold'>Documents for {PROVIDER_TYPES[license.type]}</h2>
          { license.registry_url && <a href={license.registry_url} className='text-navyBlue hover:font-semibold'><PopOver message={"Registry link"}><FiExternalLink className="h-5 w-5"/></PopOver></a>}
          <Badge className={`${PROFILE_LICENSE_STATUS[license.status].css}`}>{PROFILE_LICENSE_STATUS[license.status].value}</Badge>
        </div>
        {license.verifications.length > 1 && <>
          <div className="flex flex-col space-y-4">
            <div className='border mt-5'></div>
            {license.verifications.map((docs, index) => (
              <Accordion title={docs.license_requirement.attribute.organiser_title}>
                <AccordionDocumentData
                  key={index}
                  file={docs.file}
                  valid_from={docs.valid_from}
                  valid_to={docs.valid_to}
                  onImageView={onImageView}
                />
              </Accordion>))}
          </div></>} </>
      ))}
      {viewDocumentModal && <DocumentsViewModal file={file} onClose={onClose} />}
    </>
  )
}

export default DocumentsCard