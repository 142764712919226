import useListings from '../api/hooks/useListings';
import Layout from '../components/Layout';
import ListingTable from '../components/ListingTable';
import { useState } from 'react'
import CreateListingButton from '../components/CreateListingButton';
import CreateBookingModal from '../components/CreateBookingModal';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react'
import useAddress from '../api/hooks/useAddress';
import useProfileTypes from '../api/hooks/useProfileTypes';
import useRates from '../api/hooks/useRates';
import useOrganiser from '../api/hooks/useOrganiser';
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import EmptyList from '../components/EmptyList';
import Title from '../components/Title';
import withPermissionRequired from "../services/auth0";

function Listings() {
  const [page, setPage] = useState(1)
  const [viewModal, setViewModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editList, setEditList] = useState({})
  const [orderBy, setOrderBy] = useState("created_on")
  const [direction, setDirection] = useState(false)
  const {
    isLoading,
    isError,
    error,
    data: lists,
  } = useListings(page, orderBy, direction);
  const {
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    data: profiles,
  } = useProfileTypes();
  const {
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
    error: errorAddress,
    data: address,
  } = useAddress();
  const {
    isLoading: isLoadingRates,
    isError: isErrorRates,
    error: errorRates,
    data: rates,
  } = useRates();
  const {
    isLoading: isLoadingOrganiser,
    isError: isErrorOrganiser,
    error: errorOrganiser,
    data: organiser,
  } = useOrganiser();
  const tableHead = [{ name: 'TYPE', value: "provider_type", isSorting: true },
  { name: 'STATUS', value: "status", isSorting: true },
  { name: 'START TIME', value: "start_time", isSorting: true },
  { name: 'REPEATS', value: "repeats", isSorting: false },
  { name: 'ACTIONS', value: "actions", isSorting: false },
  { name: 'INTERESTS/ASSIGNMENTS', value: "interest", isSorting: false }]
  let content = '';
  if (isLoading || isLoadingProfile || isLoadingAddress || isLoadingRates || isLoadingOrganiser) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (isErrorAddress) {
    content = <ErrorMessage errorMessage={errorAddress.message} />
  }
  if (isErrorProfile) {
    content = <ErrorMessage errorMessage={errorProfile.message} />
  }
  if (isErrorRates) {
    content = <ErrorMessage errorMessage={errorRates.message} />
  }
  if (isErrorOrganiser) {
    content = <ErrorMessage errorMessage={errorOrganiser.message} />
  }
  if (lists && profiles && address && rates && organiser) {
    const totalPages = Math.ceil(lists.count / lists.page_size);
    const previousPage = () => setPage(page - 1);
    const onClose = () => setViewModal(false);
    const nextPage = () => setPage(page + 1);
    const editBookingButtonClick = () => {
      setViewModal(true);
      setIsEditMode(true);
    }
    const createBookingButtonClick = () => {
      setViewModal(true);
      setIsEditMode(false);
      setEditList({});
    }
    const pagesArray = Array(totalPages).fill().map((_, index) => index + 1);
    content = <>
      <div className={`flex flex-row lg:flex-row md:flex-row flex-col gap-y-2 ${lists.count < 1 ? "lg:justify-end md:justify-end" : "lg:justify-between md:justify-between"}`}>
        {lists.count >= 1 && <Title title={"Listings"} />}
        <CreateListingButton createBookingButtonClick={createBookingButtonClick} listCount={lists.count} />
      </div>
      {lists.count >= 1 ? <ListingTable
        listing={lists.results}
        tableHead={tableHead}
        pagesArray={pagesArray}
        page={page}
        count={lists.page_size}
        setPage={setPage}
        previousPage={previousPage}
        nextPage={nextPage}
        editBookingButtonClick={editBookingButtonClick}
        setEditList={setEditList}
        totalCount={lists.count}
        setOrderBy={setOrderBy}
        setDirection={setDirection}
        orderBy={orderBy}
        profiles={profiles}
      /> :
        <EmptyList message="No listings yet!" />}
      {viewModal && <CreateBookingModal
        onClose={onClose}
        isEditMode={isEditMode}
        editList={editList}
        profiles={profiles}
        address={address}
        rates={rates}
        organiser={organiser}
      />}
    </>
  }

  return (
    <div>
      <Layout>
        <TopBar />
        {content}
      </Layout>
    </div>
  );
}

export default withPermissionRequired(Listings, ["list:listing", "create:listing", "update:listing", "detail:listing"], true);
