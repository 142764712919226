import React from 'react'
import Modal from './Modal'
import { useState } from 'react'
import BackdropLoading from './BackdropLoading';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ORIENTATION_TYPE } from '../data/OrientationType';
import Badge from './Badge';
import CheckBox from './CheckBox';
import { useLocation } from 'react-router-dom'
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TinyMceEditor from './TinyMceEditor';

const CreateOrientationModal = (props) => {
  const [title, setTitle] = useState(() => {
    const initialState = props.isEditMode ? props.editList.title : "";
    return initialState;
  });
  const [description, setDescription] = useState(() => {
    const initialState = props.isEditMode ? props.editList.content : "";
    return initialState;
  });
  const [type, setType] = useState(() => {
    const initialState = props.isEditMode ? props.editList.type : 10;
    return initialState;
  });
  const [providerType, setProviderType] = useState(() => {
    const initialState = props.isEditMode ? props.profiles.map((profile) => (props.editList.licenses.includes(profile.key)
      ? { ...profile, checked: true } : { ...profile, checked: false })) : props.profiles.map((profile) => ({ ...profile, checked: false }))
    return initialState;
  });
  const [isChecked, setIsChecked] = useState(() => {
    const initialState = props.isEditMode ? (props.editList.is_active ? true : false) : true;
    return initialState;
  });

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  const location = useLocation();
  const updateKey = location.pathname === "/orientations" ? "orientations" : "orientation"
  let content = '';
  const addOrientation = useMutation(async (newList) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/orientation/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'orientation',
    onSuccess: () => {
      queryClient.invalidateQueries("orientations");
      props.onClose();
      toast.success(<ToastNotification message="Orientation created successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.data.custom)
        toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );

  const updateOrientaion = useMutation(async (newList) => {
    return axios.put(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/orientation/${props.editList.id}/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'orientationUpdate',
    onSuccess: () => {
      queryClient.invalidateQueries(updateKey);
      props.onClose();
      toast.success(<ToastNotification message="Orientation updated successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.data.custom)
        toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );
  if (addOrientation.isLoading) {
    content = <BackdropLoading />
  }
  if (updateOrientaion.isLoading) {
    content = <BackdropLoading />
  }

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };
  const typeChangeHandler = (event) => {
    setType(event.target.value)
  }
  const checkboxChangeHandler = (index) => {
    setProviderType(
      providerType.map((type, currentIndex) =>
        currentIndex === index
          ? { ...type, checked: !type.checked }
          : type
      )
    )
  }
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const orientationHandler = (event) => {
    event.preventDefault();
    if (title.trim().length === 0 || title.trim().length < 5) {
      toast.warn(<ToastNotification message="Title should be more than 5 characters" status="warn" />);
      return;
    }
    if (description.trim().length === 0 || description.trim().length < 10) {
      toast.warn(<ToastNotification message="Description should be more than 10 characters" status="warn" />);
      return;
    }
    if (providerType.length === 0) {
      toast.warn(<ToastNotification message="Please select a provider type" status="warn" />);
      return;
    }
    const liscenses = providerType.filter(pType => pType.checked).map(pType => pType.key);
    const data = {
      title: title,
      content: description,
      type: type,
      licenses: liscenses,
      is_active: isChecked
    }
    if (props.isEditMode) {
      updateOrientaion.mutate(data)
    }
    else {
      addOrientation.mutate(data)
    }
  }

  return (<>
    <Modal title={props.isEditMode ? "Edit Orientation" : "New Orientation"} buttonName={props.isEditMode ? "Update" : "Create"}
      onClose={props.onClose} width={"max-w-4xl"}
      onSubmit={orientationHandler} >
      <div className='p-6 space-y-6 xs:space-y-4'>
        <div>
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-600">Title</label>
          <input type="text" id="title" value={title}
            onChange={titleChangeHandler}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="title" required />
        </div>
        <div>
          <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-600">Description</label>
          <TinyMceEditor height={300} description={description} setDescription={setDescription} />
        </div>
        <div>
          <label htmlFor="type" className="block  mb-2 text-sm font-medium text-gray-600">Type of orientation</label>
          <div className='flex flex-row gap-x-8'>
            {ORIENTATION_TYPE.map((typeOfOrientation, index) => (
              <div className='flex flex-row gap-x-2' key={index}>
                <input
                  id="type"
                  type="radio"
                  value={typeOfOrientation.value}
                  checked={typeOfOrientation.value === Number(type)}
                  onChange={typeChangeHandler}
                />
                <label htmlFor="shift" className="text-sm font-medium text-gray-600"><Badge className={typeOfOrientation.css}>{typeOfOrientation.label}</Badge></label>
              </div>)
            )}
          </div>
        </div>
        <div>
          <label htmlFor="providerType" className="block  mb-2 text-sm font-medium text-gray-600">Provider Type</label>
          <div className="flex flex-row gap-x-3 flex-wrap gap-y-2 mt-2">
            {providerType.map((type, index) => (
              <CheckBox
                key={index}
                isChecked={type.checked}
                checkboxChangeHandler={() => checkboxChangeHandler(index)}
                label={type.value}
              />
            ))}</div>
        </div>
        <div className="flex items-center flex-row space-x-3 mb-4">
          <input id="repeats" type="checkbox" value="" checked={isChecked}
            onChange={handleOnChange}
            className="w-4 h-4 text-navyBlue bg-gray-100 rounded border-gray-300 focus:ring-navyBlue" />
          <label htmlFor="repeats" className="ml-2 text-sm font-medium text-gray-600">Is active</label>
        </div>
      </div>
    </Modal>
    {content}</>
  )
}

export default CreateOrientationModal