import React from 'react'
import { ImFileEmpty } from "react-icons/im";

const EmptyList = ({ message }) => {
  return (
    <div className='flex items-center justify-center h-96 p-10'>
      <div className='flex lg:flex-row md:flex-row flex-col gap-x-5'>
        <ImFileEmpty className='text-violet-500 w-8 h-8 animate-bounce' />
        <span className='font-semibold text-xl'>{message}</span>
      </div>
    </div>
  )
}

export default EmptyList