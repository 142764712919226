import React, { useState } from "react";
import Rating from './Rating'
import Modal from './Modal'
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import BackdropLoading from "./BackdropLoading";

const RatingModal = ({ setShowRatingModal, assignmentId }) => {
  const [rate, setRate] = useState(1);
  const [review, setReview] = useState("");

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let content = '';

  const addRating = useMutation(async (addRate) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/${assignmentId}/rating/`, addRate, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'addRating',
    onSuccess: () => {
      queryClient.invalidateQueries("assignment");
      setShowRatingModal(false);
      toast.success(<ToastNotification message="Rating registered successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        if (typeof (error.response.data) === "object") {
          if (Object.keys(error.response.data).length > 0) {
            var key = Object.keys(error.response.data)[0];
            if (key.length > 0)
              toast.warn(<ToastNotification message={error.response.data[key][0]} status="warn" />);
            else
              toast.warn(<ToastNotification message={error.message} status="warn" />);
          }
          else
            toast.warn(<ToastNotification message={error.message} status="warn" />);
        }
        else
          toast.warn(<ToastNotification message={error.message} status="warn" />);
      }
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );
  if (addRating.isLoading) {
    content = <BackdropLoading />
  }

  const onClose = () => setShowRatingModal(false);
  const rateHandler = (event) => {
    event.preventDefault();
    addRating.mutate({
      value: rate,
      review: review.trim().length === 0 ? null : review,
    })
  }
  return (<>
    <Modal title={"Rate this assignment"} buttonName={"Rate"}
      onClose={onClose}
      onSubmit={rateHandler}
      width={"max-w-md"}>
      <Rating rate={rate} setRate={setRate} review={review} setReview={setReview} />
    </Modal>{content}</>
  )
}

export default RatingModal