import React from 'react'
import { AiFillEdit } from "react-icons/ai";
import PopOver from './PopOver';
import withPermissionRequired from "../services/auth0";

const EditListIcon = ({ handleEditClick }) => {
  return (
    <PopOver message={"Edit"}><AiFillEdit className='hover:font-bold hover:cursor-grab text-gray-600 h-7 w-7 hover:scale-125'
      onClick={handleEditClick} /></PopOver>
  )
}

export default withPermissionRequired(EditListIcon, "update:listing")