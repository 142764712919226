import React from 'react'
import Card from './Card';
import withPermissionRequired from "../services/auth0";
import ProfileHeader from './ProfileHeader';

const InterestDetailCard = ({ userProfile }) => {
  return (
    <Card>
      <ProfileHeader
        name={userProfile.name}
        image={userProfile.image}
        licenses={userProfile.licenses}
        areas={userProfile.areas}
        type={"interest"}
      />
      <div className='border mt-2'></div>
      <h2 className='font-bold text-base py-4'>Description</h2>
      <div>{userProfile.description}</div>
      {/* <div className='border mt-5'></div>
      <ul className='flex flex-row gap-x-8 py-3 flex-wrap gap-y-4'>
        <li className='flex flex-col gap-y-2'>
          <div className='text-base font-bold'>60+</div>
          <div className='text-sm'>Jobs finished</div>
        </li>
        <li className='flex flex-col gap-y-2'>
          <div className='text-base font-bold'>2000+</div>
          <div className='text-sm'>Hours worked</div>
        </li>
      </ul> */}
    </Card>
  )
}

export default withPermissionRequired(InterestDetailCard, ["detail:interest"], false)