import React from 'react'
import withPermissionRequired from "../services/auth0";
import EditListButton from './EditListButton';
import CancelListButton from './CancelListButton';

const ListActions = ({canEdit, canCancel, editBookingButtonClick, setViewDeleteModal}) => {
  return (<>
    {(canEdit || canCancel) &&  <><span className='text-base font-bold'>Actions </span>
    {canEdit && <EditListButton editBookingButtonClick={editBookingButtonClick}/>}
    {canCancel && <CancelListButton setViewDeleteModal={setViewDeleteModal}/>}
       <div className='border mt-5'></div> </>}</>
  )
}

export default withPermissionRequired(ListActions, [ "update:listing"], false)