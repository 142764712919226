import React from 'react'
import Layout from '../components/Layout';
import Card from '../components/Card';
import InterestDetailActionCard from '../components/InterestDetailActionCard';
import InterestDetailCard from '../components/InterestDetailCard';
import DocumentsCard from '../components/DocumentsCard';
import { useParams } from 'react-router';
import useProfile from '../api/hooks/useProfile';
import { useLocation } from "react-router-dom";
import { useState } from 'react'
import useListing from '../api/hooks/useListing';
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import useInterestLogs from '../api/hooks/useInterestLogs';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import InterestLogsCard from '../components/InterestLogsCard';
import Title from '../components/Title';
import withPermissionRequired from "../services/auth0";

const Interest = () => {
  const location = useLocation();
  const [status, setStatus] = useState(location.state.status)
  const { interestId, profileId, listingId: bookingId } = useParams();
  const { isLoading,
    isError,
    error,
    data: userProfile } = useProfile(profileId, ".verifications");
  const { isListingLoading,
    isListingError,
    listingError,
    data: list } = useListing(bookingId);
  const { isLogsLoading,
    isError: isLogsError,
    error: logsError,
    data: logs } = useInterestLogs(interestId);
  useEffect(() => {
    if (isLogsError && logsError.request.status !== 403 && logsError.request.status !== 404) {
      toast.warn(<ToastNotification message={`Couldn't show timeline: ${logsError.message}`} status="warn" />);
    }
  }, [isLogsError, logsError]);
  let content = ''
  if (isLoading || isListingLoading || isLogsLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (isListingError) {
    content = <ErrorMessage errorMessage={listingError.message} />
  }
  if (userProfile && list) {
    content = <>
      <Title title={`Interest of ${userProfile.name} for listing`}
        linkTo={`/listings/${bookingId}`}
        linkTitle={bookingId} />
      <div className="flex lg:flex-row md:w-full w-full lg:justify-between gap-y-10 md:flex-col-reverse flex-col-reverse justify-center">
        {/* <div className="flex flex-row justify-center gap-x-10 tablet:flex-col-reverse tablet:gap-y-10 "> */}
        <div className='basis-[67%]  flex flex-col gap-y-8'>
          <InterestDetailCard
            userProfile={userProfile}
            list={list} />
          {logs && <Card>
            <InterestLogsCard logs={logs} />
          </Card>}
        </div>
        {/* <div className='basis-1/4  h-full flex flex-col space-y-4'> */}
        <div className='basis-[30%]  h-full flex flex-col gap-y-8'>
          <InterestDetailActionCard
            status={status}
            interestId={interestId}
            bookingId={bookingId}
            setStatus={setStatus}
            listingStatus={list.status}
            availability={userProfile.availability}
          />
          <Card>
            <DocumentsCard licenses={userProfile.licenses} />
          </Card>
        </div>
      </div>
    </>
  }
  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  )
}

export default withPermissionRequired(Interest, ["detail:interest", "update:interest"], true)