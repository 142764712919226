import React from 'react'

const PaginationData = ({ page, pagesArray, totalCount, count }) => {
  return (
    <>
      {page === pagesArray.length ? (totalCount - ((pagesArray.length - 1) * count)) === 1 ? <div className='text-sm text-gray-500 ml-6'>Showing {totalCount}  of {totalCount} listings</div>
        : (<div className='text-sm text-gray-500 ml-6'>Showing {(page * count) - (count - 1)} to {totalCount}  of {totalCount} listings</div>)
        : (<div className='text-sm text-gray-500 ml-6'>Showing {(page * count) - (count - 1)} to {page * count}  of {totalCount} listings</div>)
      }
    </>
  )
}

export default PaginationData