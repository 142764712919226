import React from 'react'
import moment from 'moment';
import { BILLING_STATUS } from '../data/BillingStatus';
import Badge from './Badge';
import { Link } from 'react-router-dom';
import useBilling from '../api/hooks/useBilling';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import { useEffect } from 'react';

const BillingTableRows = ({ billingList, setInvoiceLoading }) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const { isLoading,
    isError,
    error,
    isFetching,
    refetch,
    data: billing } = useBilling(billingList.id);

  useEffect(() => {
    if (billing && isEnabled) {
      const timer = setTimeout(() => {
        const blob = new Blob([billing], { type: "application/pdf" });
        window.open(URL.createObjectURL(blob));
        setInvoiceLoading(false)
        setIsEnabled(false)
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [billing, isEnabled, setInvoiceLoading]);
  let billingStatus = 1;
  if (Number(billingList.total) === Number(billingList.balance))
    billingStatus = 30;
  else if (Number(billingList.balance) === 0)
    billingStatus = 10;
  else
    billingStatus = 20;

  const handleInvoiceClick = () => {
    refetch();
    setIsEnabled(true)
    if (isError)
      toast.warn(<ToastNotification message={error.message} status="warn" />);
    else if (isLoading && !isFetching)
      setInvoiceLoading(true)
    else if (isFetching)
      setInvoiceLoading(true)
    else
      setInvoiceLoading(true)
  }

  return (
    <tr className="border-b border-grey hover:cursor-grab hover:bg-violet-100 odd:bg-white even:bg-slate-50"
      onClick={handleInvoiceClick}>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
        {billingList.id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
        {moment(billingList.created_on).format('MMMM Do YYYY')}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900">{moment(billingList.due_date).format('MMMM Do YYYY')}</td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900 font-bold">{Number(billingList.total)} CAD</td>
      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
        <Badge className={BILLING_STATUS[billingStatus].css}>{BILLING_STATUS[billingStatus].value}</Badge>
      </td>
      <td className="py-4 whitespace-nowrap text-gray-900">
        <Link to={`/billing/assignments/invoice/${billingList.id}`} className="text-navyBlue font-semibold text-base hover:cursor-grab hover:underline hover:underline-offset-4 px-3">Show related assignments</Link>
      </td>
    </tr>
  )
}

export default BillingTableRows