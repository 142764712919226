import React from 'react'
import { MdError } from "react-icons/md";
import Card from './Card';

const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className='flex items-center justify-center h-96 p-5'>
      <Card className="w-fit rounded-lg">
        <div className='flex lg:flex-row md:flex-row flex-col gap-x-5'>
          <MdError className='text-red-500 w-10 h-10 animate-bounce' />
          <span className='font-semibold text-xl'>{errorMessage}</span>
        </div>
      </Card>
    </div>
  )
}

export default ErrorMessage