import React from 'react'
import InterestTable from '../components/InterestTable';
import { useState } from 'react'
import { useParams } from 'react-router';
import useAssignmentsByInvoice from '../api/hooks/useAssignmentsByInvoice';
import Layout from '../components/Layout';
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import EmptyList from '../components/EmptyList';
import Title from '../components/Title';
import withPermissionRequired from "../services/auth0";

const AssignmentsByInvoice = () => {
  const { invoiceId } = useParams();
  const [assignmentPage, setAssignmentPage] = useState(1)
  const [orderBy, setOrderBy] = useState("created_on")
  const [direction, setDirection] = useState(false)
  const {
    isLoading,
    isError,
    error,
    data: assignments,
  } = useAssignmentsByInvoice(assignmentPage, invoiceId, orderBy, direction);
  const tableHead = [{ name: 'PROFESSIONAL', value: "professional", isSorting: false },
  { name: 'START TIME', value: "start", isSorting: true },
  { name: 'END TIME', value: "end", isSorting: true },
  { name: 'LISTING ID', value: "listing", isSorting: true },
  { name: 'STATUS', value: "status", isSorting: true }]
  let content = '';
  if (isLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (assignments) {
    const totalPages = Math.ceil(assignments.count / assignments.page_size);
    const previousPage = () => setAssignmentPage(assignmentPage - 1);
    const nextPage = () => setAssignmentPage(assignmentPage + 1);
    const pagesArray = Array(totalPages).fill().map((_, index) => index + 1);
    content = <>
      <Title title={`Assignments for invoice : ${invoiceId}`} />
      {assignments.count >= 1 ? <InterestTable
        listing={assignments.results}
        tableHead={tableHead}
        pagesArray={pagesArray}
        page={assignmentPage}
        count={assignments.page_size}
        setPage={setAssignmentPage}
        previousPage={previousPage}
        nextPage={nextPage}
        tableValue="assignment"
        totalCount={assignments.count}
        setOrderBy={setOrderBy}
        setDirection={setDirection}
        orderBy={orderBy}
      /> : <EmptyList message="No assignments yet!" />}
    </>
  }
  return (
    <div>
      <Layout>
        <TopBar />
        {content}
      </Layout>
    </div>
  )
}


export default withPermissionRequired(AssignmentsByInvoice, ["list:invoice"], true);