import React from 'react'
import Badge from './Badge';
import { ASSIGNMENT_STATUS } from '../data/AssignmentStatus';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import dummyImage from "../data/dummyImage.jpeg"
import { GrLocation } from "react-icons/gr";

const AssignmentTableRows = ({ assignmentList }) => {
  const navigate = useNavigate();
  return (
    <tr className="border-b border-grey hover:cursor-grab hover:bg-violet-100 odd:bg-white even:bg-slate-50"
      onClick={() => navigate(`/listing/${assignmentList.listing}/assignments/${assignmentList.id}/profile/${assignmentList.profile.id}`
      )}>
      <td className="px-1 py-1 whitespace-nowrap text-gray-900 flex flex-row">
        <div className="py-2 px-3 mt-2">
          {assignmentList.profile.image.length > 1
            ? <img src={assignmentList.profile.image} className="rounded-full w-12 h-12 tabletx:hidden shrink-0" alt={assignmentList.profile.full_name} />
            : <img src={dummyImage} className="rounded-full w-12 h-12 tabletx:hidden shrink-0" alt={assignmentList.profile.name} />}
        </div>
        <ul className="flex flex-col">
          <li className='mt-3 font-bold text-sm'>{assignmentList.profile.name}</li>
          <li className='mt-2 text-xs flex flex-row space-x-2'>
            <GrLocation className='w-3.5 h-3.5' />
            <div>{`${assignmentList.profile.areas.length} ${assignmentList.profile.areas.length > 1 ? "areas" : "area"}`}</div>
          </li>
        </ul>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900">{moment(assignmentList.start).format('MMMM Do YYYY, hh:mm a')}</td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900">{moment(assignmentList.end).format('MMMM Do YYYY, hh:mm a')}</td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-900">{assignmentList.listing}</td>
      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
        <Badge className={ASSIGNMENT_STATUS[assignmentList.status].css}>{ASSIGNMENT_STATUS[assignmentList.status].value}</Badge>
      </td>
    </tr>
  )
}

export default AssignmentTableRows