import React from 'react'
import moment from 'moment'
import parse from "html-react-parser";

const NotificationItems = ({ notification }) => {
  return (
    <><h3 className='text-base font-bold mt-2'>{notification.data.subject}</h3>
      <span className='text-xs'>{moment(notification.created_on).fromNow()}</span>
      <p className='py-2 text-sm'>{parse(notification.data.content)}</p>
      <div className='border mt-4 xs:mt-3'></div></>
  )
}

export default NotificationItems