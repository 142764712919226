import React from 'react'
import PdfViewer from './PdfViewer'

const DocumentsViewModal = ({ file, onClose }) => {
  return (
    <div id="defaultModal" aria-hidden="true" className="backdrop-contrast-50 backdrop-blur-lg flex overflow-x-hidden items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 ">
      <div className="relative p-4  w-screen md:max-w-xl lg:max-w-4xl h-screen">
        <div className="relative bg-white rounded-lg shadow">
          
          <div className='overflow-y-auto'>
          <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              text-sm p-1.5 ml-auto inline-flex flex w-full  justify-end sticky top-0"
            onClick={() => onClose()}>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path></svg>
          </button>
            {file.endsWith(".pdf") ? (
              <PdfViewer pdf={file}/>)
              : <img src={file} alt={file}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsViewModal