import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getAssignmentsByInvoice = async (token, pageNo=1, invoiceId, orderBy, direction) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/invoice/${invoiceId}?expand=profile.areas&page=${pageNo}&order_by=${orderBy}&direction=${direction ? "asc" : "desc"}`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useAssignmentsByInvoice(pageNo, invoiceId, orderBy, direction) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['assignmentsByInvoice', pageNo, invoiceId, orderBy, direction], () => getAssignmentsByInvoice(token, pageNo, invoiceId, orderBy, direction));
}