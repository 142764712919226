import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getAssignmentById = async (token, assignmentId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/${assignmentId}/?expand=profile.licenses,listing`,
    {
      headers: {
        Authorization: `Bearer ${await token}`,
      },
    }
  );

  return data;
}

export default function useAssignment(assignmentId) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['assignment', assignmentId], () => getAssignmentById(token, assignmentId));
}
