import React from 'react'

const TablePagination = ({ page, pagesArray, setPage, previousPage, nextPage }) => {
  return (
    <>
      <nav aria-label="Page navigation example" className='flex justify-center'>
        <ul className="inline-flex -space-x-px items-center mt-6">
          <li>
            <button type='button' onClick={() => previousPage()} disabled={page === 1} className={`${page === 1 ? "bg-badgeGrey text-grey" : "hover:cursor-grab hover:bg-shadowBlue hover:text-navyBlue bg-white text-black"} py-2 px-3 ml-0 leading-tight  rounded-l-lg border border-grey`}>Previous</button>
          </li>
          {page > 5 && <li className={`text-black bg-white py-2 px-3  leading-tight border border-grey  lg:block xl:block 2xl:block hidden md:hidden`}>
            {"..."}
          </li>}
          {pagesArray.map((pg, index) => (
            (pg >= (page - 4) && pg <= (page + 4)) && <li key={index} onClick={() => setPage(pg)} className={`${page === pg ? "bg-shadowBlue text-navyBlue" : "text-black bg-white"} py-2 px-3 hover:cursor-grab leading-tight border border-grey hover:bg-shadowBlue hover:text-navyBlue lg:block xl:block 2xl:block hidden md:hidden`}>
              {pg}
            </li>
          ))}
          {page < (pagesArray.length - 4) && <li className={`text-black bg-white py-2 px-3  leading-tight border border-grey  lg:block xl:block 2xl:block hidden md:hidden`}>
            {"..."}
          </li>}
          <li>
            <button type='button' onClick={() => nextPage()} disabled={page === pagesArray.length} className={`${page === pagesArray.length ? "bg-badgeGrey text-grey" : "hover:cursor-grab hover:bg-shadowBlue hover:text-navyBlue bg-white text-black"} text-black  py-2 px-3 ml-0 leading-tight  rounded-r-lg border border-grey`}>Next</button>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default TablePagination