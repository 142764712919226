import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getBilling = async (token, billingId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/finance/invoice/${billingId}/`, {
    headers: {
      Authorization: `Bearer ${await token}`
    },
    responseType: 'blob'
  });
  return data;
};

export default function useBilling(billingId) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['billing', billingId], () => getBilling(token, billingId), {
    enabled: false,
  });
}