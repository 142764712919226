import React from 'react'
import Button from './Button'
import withPermissionRequired from "../services/auth0";

const AssignmentActions = ({ canCancel, canNoShow, canEndOrganiser, canEscalate, handleAssignmentAction }) => {
  return (
    <>
      {canCancel && <Button className="text-red-600 bg-white border border-red-600 py-1.5 hover:bg-red-600 hover:text-white"
        onClick={() => handleAssignmentAction("Cancel", "cancel_organiser")}
      >Cancel</Button>}
      {canNoShow && <Button className="text-red-600 bg-white border border-red-600 py-1.5 hover:bg-red-600 hover:text-white"
        onClick={() => handleAssignmentAction("No Show", "no_show")}
      >No show</Button>}
      {canEndOrganiser && <Button className="text-red-600 bg-white border border-red-600 py-1.5 hover:bg-red-600 hover:text-white"
        onClick={() => handleAssignmentAction("End", "complete")}
      >End</Button>}
      {canEscalate && <Button className="text-red-600 bg-white border border-red-600 py-1.5 hover:bg-red-600 hover:text-white"
        onClick={() => handleAssignmentAction("Escalate", "escalate")}
      >Escalate</Button>}</>
  )
}

export default withPermissionRequired(AssignmentActions, ["update:assignment"], false)