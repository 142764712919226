import Layout from "../components/Layout";
import { getMonth } from "../util"
import CalendarHeader from "../components/CalendarHeader";
import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import CalendarMonth from "../components/CalendarMonth";
import useAssignmentsByDate from "../api/hooks/useAssignmentsByDate";
import LoadingPulse from "../components/LoadingPulse";
import ErrorMessage from "../components/ErrorMessage";
import moment from "moment";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import TopBar from "../components/TopBar";
import withPermissionRequired from "../services/auth0";

function AssignmentsCalendarView() {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex } = useContext(GlobalContext);
  const navigate = useNavigate();
  let startDate = moment(new Date(currenMonth[0][6].$y, currenMonth[0][6].$M, 1)).subtract(10, 'd').format('YYYY-MM-DD')
  let endDate = moment(new Date(currenMonth[0][6].$y, currenMonth[0][6].$M + 1, 0)).add(10, 'd').format('YYYY-MM-DD')

  const {
    isLoading,
    isError,
    error,
    data: assignments,
  } = useAssignmentsByDate(startDate, endDate);
  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);
  let content = '';
  if (isLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (assignments) {
    // const sortedAssignments = assignments.results.sort(
    //   (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    // );
    content = <>
      <div className='flex flex-row lg:flex-row md:flex-row flex-col gap-y-2 lg:justify-between md:justify-between'>
        <Title title={assignments.results.length >= 1 ? "Assignments" : "No Assignments"} />
        <Button className="px-4 py-1.5 lg:mt-4 md:mt-4 mb-2" onClick={() => navigate("/assignments/all")}>View all assignments</Button>
      </div>
      <div className="flex flex-col h-full">
        <CalendarHeader />
        <div className=" flex h-full max-w-[1800px] overflow-x-auto">
          {/* <CalendarSidebar /> */}
          <CalendarMonth
            month={currenMonth}
            assignments={assignments.results} />
        </div>
      </div></>
  }
  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  );
}

export default withPermissionRequired(AssignmentsCalendarView, ["list:assignment"], true);
