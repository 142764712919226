import React from 'react'
import withPermissionRequired from "../services/auth0";
import Button from './Button';

const InterestActions = ({ acceptInterestButtonClick, rejectInterestButtonClick }) => {
  return (<>
    <div className='border mt-5'></div>
    <span className='text-base font-bold'>Actions </span>
    <Button
      className="text-navyBlue bg-white border border-navyBlue py-1.5 hover:bg-navyBlue hover:text-white"
      onClick={acceptInterestButtonClick}
    > Accept Interest</Button>
    <Button className="text-navyBlue bg-white border border-navyBlue py-1.5 hover:bg-navyBlue hover:text-white"
      onClick={rejectInterestButtonClick}
    >Reject Interest</Button> </>
  )
}

export default withPermissionRequired(InterestActions, ["update:interest"], false)