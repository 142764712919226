import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);
  return (<>
    <nav className={`flex ${props.className ? props.className : ''}`} aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {pathnames.length > 1 ? pathnames.length === 2 ? <>
          <li className="inline-flex items-center">
            <Link to={`/${pathnames[0]}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
              <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
              {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)}
            </Link>
          </li>
          {location.pathname.includes("assignments") ? (<li className="inline-flex items-center">
            <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
            <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[1].charAt(0).toUpperCase() + pathnames[1].substring(1, pathnames[1].length)}</span>
          </li>)
            : (<>
              {/* {location.pathname.includes("listings") ? <li className="inline-flex items-center">
            <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
            <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">Listing Detail - {pathnames[1]}</span>
          </li> : <li className="inline-flex items-center">
            <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
            <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[1].charAt(0).toUpperCase() + pathnames[1].substring(1, pathnames[1].length)}</span>
          </li>}    */}
              <li className="inline-flex items-center">
                <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length - 1)} Detail - {pathnames[1]}</span>
              </li>
            </>
            )} </> : pathnames.length === 3 ? (<>
              <li className="inline-flex items-center">
                <Link to={`/${pathnames[0]}s`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                  {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)}s
                </Link>
              </li>
              <li className="inline-flex items-center">
                <Link to={`/${pathnames[0]}s/${pathnames[1]}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                  {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)} Detail - {pathnames[1]}
                </Link>
              </li>
              <li className="inline-flex items-center">
                <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[2].charAt(0).toUpperCase() + pathnames[2].substring(1, pathnames[2].length)} By Listing</span>
              </li>
            </>
            ) : location.pathname.includes("billing") ?
          (<><li className="inline-flex items-center">
            <Link to={`/${pathnames[0]}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
              <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
              {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)}
            </Link>
          </li>
            <li className="inline-flex items-center">
              <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
              <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[1].charAt(0).toUpperCase() + pathnames[1].substring(1, pathnames[1].length)} - {pathnames[2].charAt(0).toUpperCase() + pathnames[2].substring(1, pathnames[2].length)} {pathnames[3]}</span>
            </li></>)
          : (<>
            <li className="inline-flex items-center">
              <Link to={`/${pathnames[0]}s`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)}s
              </Link>
            </li>
            <li className="inline-flex items-center">
              <Link to={`/${pathnames[0]}s/${pathnames[1]}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                {pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)} Detail - {pathnames[1]}
              </Link>
            </li>
            <li className="inline-flex items-center">
              <Link to={`/${pathnames[0]}/${pathnames[1]}/${pathnames[2]}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
                {pathnames[2].charAt(0).toUpperCase() + pathnames[2].substring(1, pathnames[2].length)} By Listing
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
              <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[2].charAt(0).toUpperCase() + pathnames[2].substring(1, pathnames[2].length - 1)} Detail</span>
            </li>
          </>
          ) : pathnames.length === 1 && (
            <li className="inline-flex items-center">
              <svg className="w-6 h-6 text-gray-400" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path></svg>
              <span className="ml-1 text-sm font-bold text-navyBlue md:ml-2">{pathnames[0].charAt(0).toUpperCase() + pathnames[0].substring(1, pathnames[0].length)}</span>
            </li>
          )}
      </ol>
    </nav>
  </>
  )
}

export default Breadcrumb