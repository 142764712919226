import React from 'react'

const PopOver = ({ message, children, className, position }) => {
  return (
    <button className={`group relative inline-block duration-300 hover:text-navyBlue text-xs ${className ? className : ''}`} type='button'>
      <span className={`font-normal absolute w-fit h-fit hidden break-words group-hover:flex ${position ? position : '-left-5'} -top-2 -translate-y-full px-2 py-1 bg-gray-600 
    rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 
    after:border-x-transparent after:border-b-transparent after:border-t-gray-700`}>{message}</span>
      {children}
    </button>
  )
}

export default PopOver