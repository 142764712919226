import React from 'react'
import ListingTableRows from './ListingTableRows';
import Table from './Table';
import TableHead from './TableHead';

const ListingTable = (props) => {
  //const orderedListing = props.listing.slice().sort((a, b) => b.updated_on.localeCompare(a.updated_on))
  return (
    <Table
      page={props.page}
      pagesArray={props.pagesArray}
      count={props.count}
      setPage={props.setPage}
      previousPage={props.previousPage}
      nextPage={props.nextPage}
      totalCount={props.totalCount}
    >
      <TableHead 
      tableHead={props.tableHead} 
      setOrderBy={props.setOrderBy}
      setDirection={props.setDirection}
      orderBy={props.orderBy}
      />
      <tbody className="text-sm">
        {props.listing.map((list) => (
          <ListingTableRows list={list}
            key={list.id}
            editBookingButtonClick={props.editBookingButtonClick}
            setEditList={props.setEditList}
            profiles={props.profiles}
          />
        ))}
      </tbody>

    </Table>
  )
}

export default ListingTable