import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";

const AssignmentBillingDetail = ({ isBillable, billingDuration }) => {
  return (
    <> <div className='border mt-5'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className="flex flex-col gap-y-2">
          <span className='text-base font-bold'>Billing</span>
          <div className="flex flex-row gap-x-2">
            <span className='text-sm'>Billable</span>
            {isBillable ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
              : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
          </div></div>
        <div className="flex flex-col gap-y-2">
          <span className='font-bold'>Billing duration</span>
          <span className="text-sm"> {billingDuration}</span>
        </div>
      </div></>
  )
}

export default AssignmentBillingDetail