import React from 'react'
import Button from './Button'
import withPermissionRequired from "../services/auth0";

const AssignmentExtendButton = ({ handleAssignmentAction }) => {
  return (
    <Button className="text-red-600 bg-white border border-red-600 py-1.5 hover:bg-red-600 hover:text-white"
      onClick={() => handleAssignmentAction("Extend", "extend")}
    >Extend</Button>
  )
}

export default withPermissionRequired(AssignmentExtendButton, ["create:extension"], false);