import React from 'react'
import dummyImage from "../data/dummyImage.jpeg"
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { useMutation } from 'react-query';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import BackdropLoading from "../components/BackdropLoading"

const UserCard = ({ organiser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let content = '';
  const changePassword = useMutation(async (newList) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/account/user/change-password/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'changePassword',
    onSuccess: (response) => {
      toast.success(<ToastNotification message={response.data.message} status="success" />);
    },
    onError: (error) => {
      if (error.response.data.detail)
        toast.warn(<ToastNotification message={error.response.data.detail} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    }
  }
  );
  if (changePassword.isLoading) {
    content = <BackdropLoading />
  }
  const changePasswordHandler = () => {
    changePassword.mutate({});
  }
  return (
    <>
      <div className="px-1 py-1 whitespace-nowrap text-gray-900 flex flex-row tablet:gap-x-1 gap-x-12">
        <div className="py-2 px-3 mt-2">
          {organiser.user.image_url.length > 1
            ? <img src={organiser.user.image_url} className="rounded-full w-24 h-24 tablet:hidden " alt={organiser.user.full_name} />
            : <img src={dummyImage} className="rounded-full w-24 h-24 tablet:hidden " alt={organiser.user.full_name} />}
        </div>
        <div className="flex-col">
          <h1 className='mt-8 font-bold text-2xl'>{organiser.user.full_name}</h1>
          <div className='text-md font-thin'>{organiser.title}</div>
          <div className='text-lg font-semibold'>{organiser.institution.name}</div>
          {/* <div className='text-sm'>{organiser.institution.address}</div> */}
        </div>
      </div>

      <div className='border mt-5'></div>
      <div className='flex lg:flex-row md:flex-row flex-col gap-y-6 lg:justify-between md:justify-between p-5'>
        {organiser.user.phone_number && <div className='flex flex-row gap-x-5'>
          <BsFillTelephoneFill className="h-6 w-6 text-violet-400" />{organiser.user.phone_number}</div>}
        <div className='flex flex-row gap-x-5'>
          <MdAttachEmail className="h-6 w-6 text-violet-400" />{organiser.user.email}</div>
      </div>
      <div className='border mt-5'></div>
      <div className='p-5 '>
        <span className="text-navyBlue hover:font-bold hover:cursor-grab" onClick={changePasswordHandler}>Change Password</span>
      </div>
      {content}
    </>
  )
}

export default UserCard