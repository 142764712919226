import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getListings = async (token, pageNo=1, orderBy, direction) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/?page=${pageNo}&order_by=${orderBy}&direction=${direction ? "asc" : "desc"}`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useListings(pageNo, orderBy, direction) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['listings', pageNo, orderBy, direction], () => getListings(token, pageNo, orderBy, direction));
}
