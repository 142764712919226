import Layout from "../components/Layout";
import useBillings from "../api/hooks/useBillings";
import TopBar from "../components/TopBar";
import EmptyList from "../components/EmptyList";
import InterestTable from "../components/InterestTable";
import ErrorMessage from "../components/ErrorMessage";
import BackdropLoading from "../components/BackdropLoading"
import LoadingPulse from "../components/LoadingPulse";
import { useState } from 'react'
import Title from "../components/Title";
import withPermissionRequired from "../services/auth0";

function Billings() {
  const [billingPage, setBillingPage] = useState(1)
  const [orderBy, setOrderBy] = useState("created_on")
  const [direction, setDirection] = useState(false)
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const {
    isLoading,
    isError,
    error,
    data: billings
  } = useBillings(billingPage, orderBy, direction);
  const tableHead =
    [{ name: 'INVOICE ID', value: "id", isSorting: true },
    { name: 'INVOICE DATE', value: "created_on", isSorting: true },
    { name: 'DUE DATE', value: "due_date", isSorting: true },
    { name: 'INVOICE AMOUNT', value: "total", isSorting: false },
    { name: 'STATUS', value: "balance", isSorting: false }]
  let content = '';
  if (isLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (billings) {
    const totalPages = Math.ceil(billings.count / billings.page_size);;
    const previousPage = () => setBillingPage(billingPage - 1);
    const nextPage = () => setBillingPage(billingPage + 1);
    const pagesArray = Array(totalPages).fill().map((_, index) => index + 1);
    content = <>
      {billings.count >= 1 ? <>
        {/* <div className='flex flex-row lg:flex-row flex-col justify-between mt-5'>
          <h2 className="text-xl font-bold">Billings </h2>
        </div> */}
        <Title title={"Billings"} />
        <InterestTable
          listing={billings.results}
          tableHead={tableHead}
          tableValue="billing"
          pagesArray={pagesArray}
          page={billingPage}
          count={billings.page_size}
          setPage={setBillingPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalCount={billings.count}
          setOrderBy={setOrderBy}
          setDirection={setDirection}
          setInvoiceLoading={setInvoiceLoading}
        /> </> : <EmptyList message="No billings yet!" />}
    </>
  }
  return (
    <div>
      <Layout>
        <TopBar />
        {content}
        {invoiceLoading && <BackdropLoading />}
      </Layout>
    </div>);
}

export default withPermissionRequired(Billings, ["list:invoice"], true);
