import React from 'react'
import useOrganiser from '../api/hooks/useOrganiser'
import UserCard from '../components/UserCard';
import LoadingPulse from '../components/LoadingPulse';
import Card from '../components/Card';
import Layout from '../components/Layout';
import TopBar from '../components/TopBar';
import ErrorMessage from '../components/ErrorMessage';
import Title from '../components/Title';
import InstitutionCard from '../components/InstitutionCard';
import TeamCard from '../components/TeamCard';

const Organiser = () => {
  const {
    isLoading,
    isError,
    error,
    data: organiser,
  } = useOrganiser();

  const userData = [
    {id: 1, name: "Sara George Markus", email: "sara_kora_mara@trinityvillage.ca", role: "System Administrator Organizer", facilities: 2},
    {id: 2, name: "Amy", email: "amy@trinityvillage.ca", role: "admin" , facilities: 1},
    {id: 3, name: "Zack", email: "zack@trinityvillage.ca", role: "staff", facilities: 1}]

  let content = '';
  if (isLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (organiser) {
    content = <>
      <Title title={" Profile Details"} />
      <div className="flex lg:flex-row md:w-full w-full lg:justify-between gap-y-10 md:flex-col-reverse flex-col-reverse justify-center">
        <div className='basis-[67%]'>
          <Card>
            <UserCard organiser={organiser} />
          </Card>
          <h2 className="text-lg font-bold py-4 mt-4">Team members</h2>
         <Card>
<TeamCard teams={userData} />
         </Card> 
        </div>
        <Card className='basis-[30%]  h-full'>
          <InstitutionCard organiser={organiser} />
        </Card>
      </div>
    </>
  }
  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  );
}

export default Organiser