import React from 'react'
import dummyImage from "../data/dummyImage.jpeg"

const InstitutionCard = ({ organiser }) => {
  return (
    <>
      {/* <div className="px-1 py-1 whitespace-nowrap text-gray-900 flex flex-row tablet:gap-x-1 gap-x-12">
        <div className="py-2 px-3 mt-2">
          {institution.image_thumbnail.length > 1
            ? <img src={institution.image_thumbnail} className="rounded-full w-24 h-24 tablet:hidden " alt={institution.name} />
            : <img src={dummyImage} className="rounded-full w-24 h-24 tablet:hidden " alt={institution.name} />}
        </div>
        <h2 className='mt-8 font-bold text-lg'>{institution.name}</h2>
      </div>
      <div className='border mt-5'></div> */}
      <div className="flex flex-col">
        <div className="py-2 px-3 mt-6">
          {organiser.institution.image_thumbnail.length > 1
            ? <img src={organiser.institution.image_thumbnail} className="rounded-full w-24 h-24 tablet:hidden " alt={organiser.institution.name} />
            : <img src={dummyImage} className="rounded-full w-24 h-24 tablet:hidden " alt={organiser.institution.name} />}
        </div>
        <div className='border mt-5 mb-5'></div>
        <div className='font-bold text-xl'>Your facilit{organiser.addresses.length > 1 ? 'ies' : 'y'} </div>
        {organiser.addresses.map((address, index) => (
          <>
            <div className='font-semibold mt-4 text-lg'>{address.facility_name}</div>
            <div className='text-sm'>{address.full_address}</div>
          </>
        ))}
        <div className='border mt-5 mb-5'></div>
        <span className='text-lg font-bold'>Time Zone </span>
        <span className='text-sm mt-4'>{organiser.institution.timezone}</span>
      </div>

    </>
  )
}

export default InstitutionCard