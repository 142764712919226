import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getAssignmentsByBooking = async (token, pageNo=1, bookingId, orderBy, direction) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/${bookingId}/assignment/?expand=profile.areas&page=${pageNo}&order_by=${orderBy}&direction=${direction ? "asc" : "desc"}`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useAssignmentsByBooking(pageNo, bookingId, orderBy, direction) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['assignmentsByBooking', pageNo, bookingId, orderBy, direction], () => getAssignmentsByBooking(token, pageNo, bookingId, orderBy, direction));
}