import React from 'react'
import Timeline from './Timeline'
import Card from './Card'
import withPermissionRequired from "../services/auth0";

const AssignmentLogsCard = ({logs}) => {
  return (
    <Card>
    <h2 className='text-lg font-bold mb-3'>Timeline</h2>
    <Timeline logs={logs}/>
    </Card>
  )
}

export default withPermissionRequired(AssignmentLogsCard, ["detail:assignment-log"], false)