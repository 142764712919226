import React, { useState } from "react";
import { ASSIGNMENT_CODE_STATUS } from '../data/AssignmentCodeStatus'
import Accordions from './Accordion'
import AssignmentActionModal from "./AssignmentActionModal";
import { ASSIGNMENT_STATUS } from "../data/AssignmentStatus";
import Badge from "./Badge";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Card from "./Card";
import withPermissionRequired from "../services/auth0";
import AssignmentActions from "./AssignmentActions";
import AssignmentExtendButton from "./AssignmentExtendButton";

const AssignmentDetailActionCard = ({ assignment }) => {
  const [viewActionModal, setViewActionModal] = useState("");
  const [actionType, setActionType] = useState("");
  const [action, setAction] = useState("");
  // const { isInvoiceLoading,
  //   isError: isInvoiceError,
  //   error: invoiceError,
  //   data: invoice } = useInvoiceByAssignment(assignment.id);
  // const { isLoading,
  //   isError,
  //   error,
  //   isFetching,
  //   refetch,
  //   data: billing } = useBilling(invoiceId);
  // useEffect(() => {
  //   if (billing && isEnabled) {
  //     const blob = new Blob([billing], { type: "application/pdf" });
  //     window.open(URL.createObjectURL(blob));
  //     setInvoiceLoading(false)
  //     setIsEnabled(false)
  //   }
  // }, [billing, isEnabled, setInvoiceLoading]);
  // useEffect(() => {
  //   if (invoice) {
  //     setInvoiceId(invoice.quickbooks_invoice_id)
  //   }
  // }, [invoice, invoiceId]);
  // useEffect(() => {
  //   if (isInvoiceError && invoiceError.request.status !== 404 && invoiceError.request.status !== 403) {
  //     toast.warn(<ToastNotification message={`Couldn't show Invoice : ${invoiceError.message}`} status="warn" />);
  //   }
  // }, [isInvoiceError, invoiceError]);
  // const handleInvoiceClick = () => {
  //   refetch();
  //   setIsEnabled(true)
  //   if (isError)
  //     toast.warn(<ToastNotification message={error.message} status="warn" />);
  //   else if (isLoading && !isFetching)
  //     setInvoiceLoading(true)
  //   else if (isFetching)
  //     setInvoiceLoading(true)
  //   else
  //     setInvoiceLoading(true)
  // }
  // let content = '';
  // if (isInvoiceLoading) {
  //   content = <LoadingPulse />
  // }
  // if (invoice) {
  //   content = <>
  //     <div className='border mt-5'></div>
  //     <span className='text-base font-bold'>Billing</span>
  //     <div className="flex flex-row gap-x-8 gap-y-4 flex-wrap text-sm">
  //       <div className="flex flex-row gap-x-2">
  //         <span className=''>Invoiced</span>
  //         {invoice.invoiced ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
  //           : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
  //       </div>
  //       <div className="flex flex-row gap-x-2">
  //         <span className='font-semibold'>Invoice date : </span>
  //         <span> {moment(invoice.invoice_on).format('MMMM Do YYYY')}</span>
  //       </div>
  //       <div className="flex flex-row gap-x-2">
  //         <span className='font-semibold hover:underline hover:underline-offset-4 text-navyBlue hover:cursor-grab' onClick={handleInvoiceClick}>
  //           Invoice id -  {invoice.quickbooks_invoice_id}</span>
  //       </div>
  //     </div>
  //   </>
  // }
  const onClose = () => setViewActionModal(false);
  const handleAssignmentAction = (actionType, action) => {
    setActionType(actionType)
    setAction(action)
    setViewActionModal(true);
  }
  return (
    <Card>
      <div className="flex flex-col space-y-4">
        <span className='text-base font-bold'>Status  </span>
        <Badge className={`${ASSIGNMENT_STATUS[assignment.status].css}`}>
          <span>{ASSIGNMENT_STATUS[assignment.status].value}</span>
        </Badge>
        {(assignment.can_cancel || assignment.can_no_show || assignment.can_end_organiser
          || assignment.can_escalate || assignment.can_extend) && <><div className='border mt-5'></div>
            <span className='text-base font-bold'>Actions</span></>
        }
        <AssignmentActions canCancel={assignment.can_cancel} canNoShow={assignment.can_no_show}
          canEndOrganiser={assignment.can_end_organiser} canEscalate={assignment.can_escalate} handleAssignmentAction={handleAssignmentAction} />
        {assignment.can_extend && <AssignmentExtendButton handleAssignmentAction={handleAssignmentAction} />}
        {(assignment.status === 20 || assignment.status === 30 || assignment.status === 80 || assignment.status === 90) && <>
          <div className='border mt-5'></div>
          <span className='text-base font-bold'>Properties</span>
          <div className="flex flex-row gap-x-8 gap-y-4 flex-wrap text-sm">
            <div className="flex flex-row gap-x-2">
              <span className=''>Started on time</span>
              {assignment.is_on_time ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
                : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
            </div>
            <div className="flex flex-row gap-x-2">
              <span className=''>Overtime</span>
              {assignment.is_overtime ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
                : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
            </div>
            <div className="flex flex-row gap-x-2">
              <span className=''>Extended</span>
              {assignment.is_extended ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
                : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
            </div>
            <div className="flex flex-row gap-x-2">
              <span className=''>Early end</span>
              {assignment.is_early_end ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
                : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
            </div>
            <div className="flex flex-row gap-x-2">
              <span className=''>Break</span>
              {assignment.is_break ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
                : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
            </div>
          </div>
        </>}
        {(assignment.status === 20 || assignment.status === 10) && <>
          <div className='border mt-5'></div>
          <span className='text-base font-bold'>Codes</span>
          {assignment.codes.length > 1 ?
            <>
              {assignment.codes.map((code, index) => (
                <Accordions key={index} title={ASSIGNMENT_CODE_STATUS[code.type]}>
                  <span className='font-bold'>{code.code}</span>
                </Accordions>
              ))}
            </>
            : <span>Assignment codes will appear 15 mins before the start time.</span>}</>}
      </div>
      {viewActionModal && <AssignmentActionModal
        actionType={actionType}
        onClose={onClose}
        action={action}
        assignmentId={assignment.id}
        name={assignment.profile.name}
      />}
    </Card>
  )
}

export default withPermissionRequired(AssignmentDetailActionCard, ["create:extension", "update:assignment", "detail:assignment"], false)