import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getInvoiceByAssignment = async (token, assignmentId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/${assignmentId}/invoice/`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useInvoiceByAssignment(assignmentId) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['invoiceByAssignment', assignmentId],
    () => getInvoiceByAssignment(token, assignmentId),
    {
      retry: (error) => error.request && error.request.status !== 404
    },);
}