import React from "react";
import { FaStar } from "react-icons/fa";

const Rating = ({rate, setRate, review, setReview}) => {
  const reviewChangeHandler = (event) => {
    setReview(event.target.value);
  };
  return (<>
    <div className="flex flex-row gap-x-3 justify-center py-8">
      {[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        return (
          <label>
            <input
              className="hidden"
              type="radio"
              value={givenRating}
              onClick={() => {
                setRate(givenRating);
              }}
            />
            <FaStar
              className={`h-8 w-8 ${(givenRating < rate || givenRating === rate) ? "text-yellow-700" : "text-grey"}`}
            />
          </label>
        );
      })}
    </div>
     <div className="flex flex-col p-6 gap-y-6">
    <div>
       <label htmlFor="review" className="block mb-2 text-sm font-medium text-gray-600">Review (optional)</label>
       <textarea id="notes" rows="4" value={review}
         onChange={reviewChangeHandler}
         className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
         placeholder="review"></textarea></div>
         </div></>
  );
}

export default Rating