import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { ASSIGNMENT_STATUS } from "../data/AssignmentStatus";
import moment from "moment";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import EventModal from "./EventModal";

export default function CalendarDay({ day, rowIdx, assignments }) {
  const navigate = useNavigate();
  const [dayEvents, setDayEvents] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  // const {
  //   setDaySelected,
  //   setShowEventModal,
  //   filteredEvents,
  //   setSelectedEvent,
  // } = useContext(GlobalContext);
  //console.log("ee", dayjs(assignments.results[0].start).format("DD-MM-YY") )
  // console.log(assignments.results[0])

  useEffect(() => {
    const events = assignments.filter(
      (evt) =>
        dayjs(evt.start).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [assignments, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-shadowBlue text-navyBlue font-bold rounded-full w-7"
      : "";
  }
  function getCurrentDayBoxClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-slate-100"
      : "bg-white";
  }
  const onClose = () => setShowEventModal(false);
  return (
    // <div className={`border border-grey flex flex-col h-[170px] lg:w-[195px] md:w-32 md:h-[170px] w-24 3xl:w-[215px] lg:h-[170px] 3xl:h-[215px]
    //  ${getCurrentDayBoxClass()}`}>
    <div className={`border border-grey flex flex-col max-h-[200px] min-h-[170px]
     ${getCurrentDayBoxClass()}`}>
      <header className={`flex ${rowIdx === 0 ? "flex-row space-x-6" : "flex-col"} items-center`}>
        {rowIdx === 0 && (
          <p className="text-sm p-2 text-gray-600 font-bold">
            {day.format("ddd").toUpperCase()}
          </p>
        )}
        <p className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()} 
         ${(Number(day.format("DD")) < 15 && rowIdx === 5) ? "text-grey" : "text-black font-semibold"}
         ${(Number(day.format("DD")) > 20 && rowIdx === 0) ? "text-grey" : "text-black font-semibold"}
         ${(Number(day.format("DD")) < 10 && rowIdx === 4) ? "text-grey" : "text-black font-semibold"}`} >{day.format("DD")}
          {/* {!((rowIdx === 0 && Number(day.format("DD")) > 20) || (rowIdx === 4 && Number(day.format("DD")) < 10)) && day.format("DD")} */}
        </p>
      </header>
      <div
        className="flex-1 text-sm">
        {dayEvents.slice(0, 3).map((evt, idx) => (
          <div
            key={idx}
            onClick={() => navigate(`/listing/${evt.listing}/assignments/${evt.id}/profile/${evt.profile.id}`)}
            className={`${ASSIGNMENT_STATUS[evt.status].css} p-1 text-sm rounded mb-1 truncate cursor-pointer`} >
            {moment(evt.start).format('hh:mm a')} - {evt.profile.name}
          </div>
        ))}
        {dayEvents.length > 3 && <Button className=" ml-1 " onClick={() => setShowEventModal(true)}>{dayEvents.length - 3} more</Button>}
      </div>
      {showEventModal && <EventModal dayEvents={dayEvents} day={day.format("DD-MM-YY")} onClose={onClose} />}
    </div>
  );
}