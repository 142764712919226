import React from 'react'
import Modal from './Modal'
import moment from 'moment'

const ListingDaysModal = ({ title, listingDates, onClose }) => {
  return (
    <Modal title={`${listingDates.length} ${listingDates.length > 1 ? "occurences" : "occurence"}`} width={"max-w-xl"} onClose={onClose} hideSubmitButton={true}>
      <div className='p-4'>
        {listingDates.map((date, index) => (
          <div
            key={index}
            className={`text-sm py-3`} >
            {moment(date.start).format('MMMM Do YYYY, hh:mm a')}&nbsp; - &nbsp;{moment(date.end).format('MMMM Do YYYY, hh:mm a')}
          </div>
        ))}</div>
    </Modal>
  )
}

export default ListingDaysModal