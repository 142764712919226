import React from 'react'
import Badge from './Badge'

const CheckBox = ({ label, isChecked, checkboxChangeHandler }) => {
  return (
    <Badge className={`${isChecked ? 'bg-shadowBlue text-navyBlue' : 'bg-badgeGrey border border-gray-300'} hover:cursor-grab`}
      onClick={checkboxChangeHandler}>
      {label}
    </Badge>
  )
}

export default CheckBox