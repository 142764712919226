import React from 'react'
import Layout from '../components/Layout';
import useInterests from '../api/hooks/useInterests';
import InterestTable from '../components/InterestTable';
import { useState } from 'react'
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import EmptyList from '../components/EmptyList';
import Title from '../components/Title';
import withPermissionRequired from "../services/auth0";

const Interests = () => {
  const [interestPage, setInterestPage] = useState(1)
  const [orderBy, setOrderBy] = useState("created_on")
  const [direction, setDirection] = useState(false)
  const {
    isLoading,
    isError,
    error,
    data: interests,
  } = useInterests(interestPage, orderBy, direction);

  const tableHead = [{ name: 'NAME', value: "name", isSorting: false },
  { name: 'EXPRESSED ON', value: "created_on", isSorting: true },
  { name: 'TYPE', value: "profile_license", isSorting: true },
  { name: 'LISTING ID', value: "listing", isSorting: true },
  { name: 'STATUS', value: "status", isSorting: true }]
  let content = '';
  if (isLoading) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }

  if (interests) {
    const totalPages = Math.ceil(interests.count / interests.page_size);
    const previousPage = () => setInterestPage(interestPage - 1);
    const nextPage = () => setInterestPage(interestPage + 1);
    const pagesArray = Array(totalPages).fill().map((_, index) => index + 1);
    content = <>
      {interests.count >= 1 ? <>
        <Title title={"Interests"} />
        <InterestTable
          listing={interests.results}
          tableHead={tableHead}
          pagesArray={pagesArray}
          page={interestPage}
          count={interests.page_size}
          setPage={setInterestPage}
          previousPage={previousPage}
          nextPage={nextPage}
          tableValue="interests"
          totalCount={interests.count}
          setOrderBy={setOrderBy}
          setDirection={setDirection}
          orderBy={orderBy}
        /> </> : <EmptyList message="No interests yet!" />}
    </>
  }

  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  );
}

export default withPermissionRequired(Interests, ["list:interest", "detail:interest"], true)