import { useParams } from 'react-router';
import useListing from '../api/hooks/useListing';
import Layout from '../components/Layout';
import Card from '../components/Card';
import ListingDetailCard from '../components/ListingDetailCard';
import ListingDetailActionCard from '../components/ListingDetailActionCard';
import useAddress from '../api/hooks/useAddress';
import useProfileTypes from '../api/hooks/useProfileTypes';
import useRates from '../api/hooks/useRates';
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import ListingLogsCard from '../components/ListingLogsCard';
import useListingLogs from '../api/hooks/useListingLogs';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Title from '../components/Title';
import withPermissionRequired from "../services/auth0";
import useOrganiser from '../api/hooks/useOrganiser';

function Listing() {
  const { listingId } = useParams();
  const { isLoading,
    isError,
    error,
    data: list } = useListing(listingId);
  const {
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    data: profiles,
  } = useProfileTypes();
  const {
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
    error: errorAddress,
    data: address,
  } = useAddress();
  const {
    isLoading: isLoadingRates,
    isError: isErrorRates,
    error: errorRates,
    data: rates,
  } = useRates();
  const {
    isLoading: isLoadingOrganiser,
    isError: isErrorOrganiser,
    error: errorOrganiser,
    data: organiser,
  } = useOrganiser();
  const { isLogsLoading,
    isError: isLogsError,
    error: logsError,
    data: logs } = useListingLogs(listingId);
  useEffect(() => {
    if (isLogsError && logsError.request.status !== 403 && logsError.request.status !== 404) {
      toast.warn(<ToastNotification message={`Couldn't show timeline: ${logsError.message}`} status="warn" />);
    }
  }, [isLogsError, logsError]);
  let content = '';
  if (isLoading || isLoadingProfile || isLoadingAddress || isLoadingRates || isLogsLoading || isLoadingOrganiser) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (isErrorAddress) {
    content = <ErrorMessage errorMessage={errorAddress.message} />
  }
  if (isErrorProfile) {
    content = <ErrorMessage errorMessage={errorProfile.message} />
  }
  if (isErrorRates) {
    content = <ErrorMessage errorMessage={errorRates.message} />
  }
  if (isErrorOrganiser) {
    content = <ErrorMessage errorMessage={errorOrganiser.message} />
  }
  if (list && address && profiles && rates) {
    content = <>
      <Title title={`Listing #${listingId}`} />
      {/* <div className="flex lg:flex-row md:w-full w-full lg:gap-x-10 gap-y-10 md:flex-col-reverse flex-col-reverse justify-center"> */}
      <div className="flex lg:flex-row md:w-full w-full lg:justify-between gap-y-10 md:flex-col-reverse flex-col-reverse justify-center">
        {/* <div className="flex  flex-row justify-center gap-x-10 tablet:flex-col-reverse tablet:gap-y-10 text-base"> */}
        {/* <div className='basis-3/5 tabletx:basis-2/3 flex flex-col space-y-4'> */}
        <div className='basis-[67%]  flex flex-col gap-y-8'>
          <ListingDetailCard list={list} profiles={profiles} />
          {logs && <Card>
            <ListingLogsCard logs={logs} />
          </Card>}
        </div>
        {/* <Card className='basis-1/4 tabletx:basis-1/3 h-full'> */}
        <div className='basis-[30%]  h-full'>
          <ListingDetailActionCard list={list} address={address} profiles={profiles} rates={rates} organiser={organiser} />
        </div>
      </div>
    </>
  }
  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  );
}

export default withPermissionRequired(Listing, ["detail:listing", "update:listing"], true);
