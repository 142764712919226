import React from 'react'
import LoadingPulse from './LoadingPulse'

const BackdropLoading = () => {
  return (
    <div id="defaultModal" className=" bg-opacity-25 backdrop-brightness-50 overflow-y-auto overflow-x-hidden flex items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0  h-full">
      <LoadingPulse />
    </div>
  )
}

export default BackdropLoading