import React from 'react'
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Accordions = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className='flex flex-row justify-between hover:cursor-grab hover:text-navyBlue hover:fond-bold'
        onClick={() => {
          setIsOpen(!isOpen)
        }} >
        <h2 className={`text-base ${isOpen && "text-navyBlue font-semibold"}`}>{props.title}</h2>
        <IoIosArrowDown className={`w-5 h-5  ${isOpen && "rotate-180 text-navyBlue"} transition duration-500 ease-in-out"`}
        />
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'}`}>
        {props.children}
      </div>
      <div className='border mt-5'></div>
    </>
  )
}

export default Accordions