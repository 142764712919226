import React, { useState } from "react";
import ProfileHeader from "./ProfileHeader";
import moment from 'moment';
import useBilling from "../api/hooks/useBilling";
import useInvoiceByAssignment from "../api/hooks/useInvoiceByAssignment";
import LoadingPulse from '../components/LoadingPulse';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Card from "./Card";
import withPermissionRequired from "../services/auth0";
import AssignmentBillingDetail from "./AssignmentBillingDetail";
import AssignmentInvoiceDetail from "./AssignmentInvoiceDetail";
import RatingModal from "./RatingModal";

const AssignmentDetailCard = ({ assignment, setInvoiceLoading }) => {
  const [invoiceId, setInvoiceId] = useState("")
  const [showRatingModal, setShowRatingModal] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const { isInvoiceLoading,
    isError: isInvoiceError,
    error: invoiceError,
    data: invoice } = useInvoiceByAssignment(assignment.id);
  const { isLoading,
    isError,
    error,
    isFetching,
    refetch,
    data: billing } = useBilling(invoiceId);
  useEffect(() => {
    if (billing && isEnabled) {
      const timer = setTimeout(() => {
        const blob = new Blob([billing], { type: "application/pdf" });
        window.open(URL.createObjectURL(blob));
        setInvoiceLoading(false)
        setIsEnabled(false)
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [billing, isEnabled, setInvoiceLoading]);
  useEffect(() => {
    if (invoice) {
      setInvoiceId(invoice.quickbooks_invoice_id)
    }
  }, [invoice, invoiceId]);
  useEffect(() => {
    if (isInvoiceError && invoiceError.request.status !== 404 && invoiceError.request.status !== 403) {
      toast.warn(<ToastNotification message={`Couldn't show Invoice : ${invoiceError.message}`} status="warn" />);
    }
  }, [isInvoiceError, invoiceError]);
  const handleInvoiceClick = () => {
    refetch();
    setIsEnabled(true)
    if (isError)
      toast.warn(<ToastNotification message={error.message} status="warn" />);
    else if (isLoading && !isFetching)
      setInvoiceLoading(true)
    else if (isFetching)
      setInvoiceLoading(true)
    else
      setInvoiceLoading(true)
  }
  let content = '';
  if (isInvoiceLoading) {
    content = <LoadingPulse />
  }
  if (invoice) {
    content = <AssignmentInvoiceDetail isInvoiced={invoice.invoiced} invoiceDate={invoice.invoice_on}
      invoiceId={invoice.quickbooks_invoice_id} handleInvoiceClick={handleInvoiceClick} />
  }
  return (
    <>
      <Card>
        <ProfileHeader
          name={assignment.profile.name}
          image={assignment.profile.image}
          licenses={assignment.profile.licenses}
          areas={assignment.profile.areas}
          type={"assignment"}
          setShowRatingModal={setShowRatingModal}
          canReview={assignment.can_review}
          providedRating={assignment.has_provider_rating}
          phNumber={assignment.profile.user.phone_number}
          eMail={assignment.profile.user.email}
        />
        <div className='border mt-2'></div>
        <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
          <div className='flex flex-col gap-y-2'>
            <span className="font-bold">Address</span>
            <div className='text-sm'>{assignment.listing.address.facility_name}, {assignment.listing.address.short_address}</div>
          </div>
        </div>
        <div className='border mt-5'></div>
        <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
          <div className="flex flex-col gap-y-2">
            <h2 className='font-bold'>Scheduled start</h2>
            <span className='text-sm'>{moment(assignment.start).format('MMMM Do YYYY, hh:mm a')}</span>
          </div>
          <div className="flex flex-col gap-y-2">
            <h2 className='font-bold'>Scheduled end</h2>
            <span className={`text-sm ${assignment.scheduled_end !== assignment.end && "text-badgeRed"}`}>{moment(assignment.scheduled_end).format('MMMM Do YYYY, hh:mm a')}{assignment.scheduled_end !== assignment.end && " (Extended)"}</span>
          </div>
        </div>
        {(assignment.actual_end && assignment.actual_start) &&
          <div className="flex flex-row gap-x-32  gap-y-4 mt-10 flex-wrap">
            {assignment.actual_start && <div>
              <h2 className='font-bold'>Actual start</h2>
              <span className='text-sm'>{moment(assignment.actual_start).format('MMMM Do YYYY, hh:mm a')}</span>
            </div>}
            {assignment.actual_end && <div>
              <h2 className='font-bold'>Actual end</h2>
              <span className='text-sm'>{moment(assignment.actual_end).format('MMMM Do YYYY, hh:mm a')}</span>
            </div>}
          </div>}
        {assignment.duration_verbose && <AssignmentBillingDetail isBillable={assignment.is_billable} billingDuration={assignment.duration_verbose} />}
        {content}
      </Card>
      {showRatingModal && <RatingModal setShowRatingModal={setShowRatingModal} assignmentId={assignment.id} />}
    </>
  )
}

export default withPermissionRequired(AssignmentDetailCard, ["detail:assignment", "detail:assignment-invoice"], false)