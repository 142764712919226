export const DAYS = [
  { name: 'Mon', value: 0 },
  { name: 'Tue', value: 1 },
  { name: 'Wed', value: 2 },
  { name: 'Thu', value: 3 },
  { name: 'Fri', value: 4 },
  { name: 'Sat', value: 5 },
  { name: 'Sun', value: 6 }];


// {
//   0: "M",
//   1: "T",
//   2: "W",
//   3: "T",
//   4: "F",
//   5: "S",
//   6: "S"
// }