import React from 'react'
import Modal from './Modal'

const ConfirmModal = (props) => {
  return (
    <Modal title={props.title} buttonName={props.buttonName} onClose={props.onClose}
      onSubmit={props.confirmModalHandler} >
      <div className='p-5 flex flex-col space-y-3'>
        <div className='font-semibold'>{props.message}</div>
      </div>
    </Modal>
  )
}

export default ConfirmModal