import React from 'react'

const Card = (props) => {
  return (
    <div className={`bg-white shadow-xl rounded-lg shadow-slate-200 p-5 xs:p-3 ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  )
}

export default Card