import React from 'react'
import moment from 'moment'

const RadioButton = ({ selectedShift, setSelectedShift, value, label, setEndTime, startTime, setOtherShiftDuration }) => {
  const shiftChangeHandler = (event) => {
    setSelectedShift(event.target.value)
    if (Number(event.target.value) === 8 || Number(event.target.value) === 6 || Number(event.target.value) === 4)
      setOtherShiftDuration("")
    if (startTime && (Number(event.target.value) === 8 || Number(event.target.value) === 6 || Number(event.target.value) === 4))
      setEndTime(moment(startTime).add(Number(event.target.value), 'hours').format('yyyy-MM-DDTHH:mm'))
  }
  return (<div className='flex flex-row gap-x-2'>
    <input
      id="shift"
      type="radio"
      name="options"
      value={value}
      checked={value === Number(selectedShift)}
      onChange={shiftChangeHandler}
    />
    <label htmlFor="shift" className="text-sm font-medium text-gray-600 mt-3 xs:mt-0">{label}</label>
  </div>
  )
}

export default RadioButton