import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import moment from 'moment';
import withPermissionRequired from "../services/auth0";

const AssignmentInvoiceDetail = ({ isInvoiced, invoiceDate, invoiceId, handleInvoiceClick }) => {
  return (
    <>
      <div className='border mt-5'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className="flex flex-col gap-y-2">
          <span className='text-base font-bold'>Invoice</span>
          <div className="flex flex-row gap-x-2">
            <span className='text-sm'>Invoiced</span>
            {isInvoiced ? <FaRegCheckCircle className='text-green-300 h-5 w-5' />
              : <FaRegTimesCircle className='text-red-300 h-5 w-5' />}
          </div></div>
        <div className="flex flex-col gap-y-2">
          <span className='font-bold'>Date</span>
          <span className="text-sm"> {moment(invoiceDate).format('MMMM Do YYYY')}</span>
        </div>
        {invoiceId && <div className="flex flex-col gap-y-2">
          <span className='text-base font-bold'>Id</span>
          <span className='font-semibold hover:underline hover:underline-offset-4 text-navyBlue hover:cursor-grab' onClick={handleInvoiceClick}>
            {invoiceId}</span>
        </div>}
      </div>
    </>
  )
}

export default withPermissionRequired(AssignmentInvoiceDetail, [ "detail:assignment-invoice"], false)