import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getListingLogs = async (token, listingId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/${listingId}/log/`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useListingLogs(listingId) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['listingLogs', listingId], () => getListingLogs(token, listingId));
}