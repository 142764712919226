import React from 'react'
import Modal from './Modal'
import { ASSIGNMENT_STATUS } from '../data/AssignmentStatus'
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const EventModal = ({ dayEvents, day, onClose }) => {
  const navigate = useNavigate();
  return (
    <Modal title={`Assignments on ${day}`} width={"max-w-md"} onClose={onClose} hideSubmitButton={true}>
      <div className='p-4'>
        {dayEvents.map((evt, index) => (
          <div
            key={index}
            onClick={() => navigate(`/listing/${evt.listing}/assignments/${evt.id}/profile/${evt.profile.id}`)}
            className={`${ASSIGNMENT_STATUS[evt.status].css} p-1 mr-3 text-sm rounded mb-1 truncate cursor-pointer`} >
            {moment(evt.start).format('hh:mm a')} - {evt.profile.name}
          </div>
        ))}</div>
    </Modal>
  )
}

export default EventModal