import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumb from '../components/Breadcrumb';
import Layout from '../components/Layout';
import jwt_decode from "jwt-decode";
import { useState, useEffect } from 'react'

const WithPermissionRequired = (
  Component, requiredRoles, showError
) => {
  return function WithAuthenticationRequired(props) {
    const [permissions, setPermissions] = useState([]);
    const { getAccessTokenSilently, isLoading } = useAuth0();
    useEffect(() => {
      const getPermissions = async () => {
        const token = getAccessTokenSilently();
        setPermissions(jwt_decode(await token).permissions);
      };
      if (!isLoading) {
        getPermissions();
      }
    }, [isLoading, getAccessTokenSilently]);
    const routeIsAuthenticated = permissions.some(item => requiredRoles.includes(item))
    return routeIsAuthenticated ? <Component {...props} /> : showError && <Layout>
      <Breadcrumb className="lg:block md:hidden hidden" /><ErrorMessage errorMessage="You are not authorized to view this page!" /></Layout> ;
  };
};

export default WithPermissionRequired;

export function ProtectedRoute({ component, ...args }) {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
}

export function Auth0ProviderWithRedirectCallback({ children, ...props }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
}
