import React from 'react'
import dummyImage from "../data/dummyImage.jpeg"

const TeamCard = ({ teams }) => {
  return (
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-x-auto">
        <table className="min-w-full">
        {teams.map((team, index) => ( 
        <tr className={`${(index+1) !== teams.length ? "border-b" : ""}`}>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
        <div className="whitespace-nowrap text-gray-900 flex flex-row tablet:gap-x-1 gap-x-2">
        <div className="p-2">
        <img src={dummyImage} className="rounded-full w-12 h-12 tablet:hidden " alt={team.name} />
        </div>
        <div className="flex-col mt-2">
          <h1 className='text-lg'>{team.name}</h1>
          <div className='text-md font-thin'>{team.email}</div>
        </div>
      </div>
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">{team.role}</td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">{team.facilities} &nbsp; {team.facilities > 1 ? "facilities" : "facility"}</td>
        </tr>))}
        </table>
      </div>
    </div>
  </div>
  )}

export default TeamCard