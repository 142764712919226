import React from 'react'
import Modal from './Modal'
import { useMutation, useQueryClient } from 'react-query';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import BackdropLoading from './BackdropLoading';

const DeleteBookingModal = (props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let content = '';
  const deleteList = useMutation(async () => {
    return axios.delete(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/${props.list.id}/`, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'bookingDelete',
    onSuccess: () => {
      // if (location.pathname === "/listings")
      //   if (props.page > 1 && props.listCount === 1) {
      //     props.setPage((props.page) - 1)
      //   }
      // if (location.pathname === `/listings/${props.list.id}`)
      //   navigate("/listings");

      props.onClose();
      queryClient.invalidateQueries('listing');
      queryClient.invalidateQueries('listingLogs');
      toast.success(<ToastNotification message="Booking cancelled successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.data.custom)
        toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    }
  }
  );
  if (deleteList.isLoading) {
    content = <BackdropLoading />
  }
  const deleteBookingHandler = (event) => {
    event.preventDefault();
    deleteList.mutate();
  }
  return (<>
    <Modal title="Cancel Listing" buttonName="Yes, Cancel" onClose={props.onClose}
      onSubmit={deleteBookingHandler} >
      <div className='p-5 flex flex-col space-y-3'>
        <div className='font-semibold text-base'>Are you sure you want to cancel this listing?</div>
      </div>
    </Modal>
    {content}</>
  )
}

export default DeleteBookingModal