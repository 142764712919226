import React from 'react'
import { Link } from 'react-router-dom'

const Title = ({ title, linkTo, linkTitle }) => {
  return (
    <h2 className="text-xl font-bold py-4">{title} {linkTo && <Link
      to={linkTo} className="text-navyBlue hover:cursor-grab hover:text-deep-purple-accent-100">{linkTitle}</Link>}</h2>
  )
}

export default Title