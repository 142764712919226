import React from 'react'
import Button from './Button'
import withPermissionRequired from "../services/auth0";

const CreateListingButton = ({ createBookingButtonClick, listCount }) => {
  return (
    <Button className={`mb-2 px-4 py-1.5 ${listCount > 0 ? "lg:mt-3 md:mt-3" : "mt-4"}`} onClick={createBookingButtonClick}>Create Listing</Button>
  )
}

export default withPermissionRequired(CreateListingButton, ["create:listing"], false)