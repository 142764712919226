import React from 'react'

const AccordionDocumentData = ({ file, valid_from, valid_to }) => {
  return (
    <div className='flex p-2 flex-col  gap-y-5 text-sm'>
     {/* <div className='flex p-5 lg:flex-row md:flex-col flex-col lg:gap-x-24 md:gap-y-10 gap-y-10'> */}
      {/* <div className='basis-1/4 mt-4'>
        {file.endsWith(".pdf") ?
          <img className="w-48 h-52 rounded-lg hover:cursor-grab hover:ring ring-shadowBlue ring hover:ring-offset-2"
            alt=""
            onClick={() => onImageView(file)}
            src={pdf} />
          : <img className="w-48 h-52 rounded-lg hover:cursor-grab hover:ring ring-shadowBlue ring hover:ring-offset-2"
            alt=""
            onClick={() => onImageView(file)}
            src={file} />
        }
      </div> */}
      {/* <div className='text-sm flex flex-col gap-y-4'> */}
        <div className='flex flex-row space-x-2'>
          <span className='font-semibold'>Valid from</span>
          <span>{valid_from} </span>
        </div>
        <div className='flex flex-row space-x-2'>
          <span className='font-semibold'>Valid to</span>
          <span>{valid_to} </span>
        </div>
      {/* </div> */}
      <a href={file} target="_blank" rel="noopener noreferrer" className="hover:underline hover:underline-offset-4 font-semibold text-navyBlue">View Document</a>
    </div>
  )
}

export default AccordionDocumentData