import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Billings from './pages/Billings';
import Listings from './pages/Listings';
import Listing from './pages/Listing';
import Assignments from './pages/Assignments';
import Interests from './pages/Interests';
import { Auth0ProviderWithRedirectCallback, ProtectedRoute } from './services/auth0';
import NotFound from './pages/NotFound';
import { ToastContainer } from 'react-toastify';
import InterestsByBooking from './pages/InterestsByBooking';
import Interest from './pages/Interest';
import AssignmentsByBooking from './pages/AssignmentsByBooking';
import Assignment from './pages/Assignment';
import Organiser from './pages/Organiser';
import AssignmentsByInvoice from './pages/AssignmentsByInvoice';
import AssignmentsCalendarView from './pages/AssignmentsCalendarView';
import Orientations from './pages/Orientations';
import Orientation from './pages/Orientation';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const queryClient = new QueryClient();

function App() {

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={`${window.location.origin}/assignments`}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          scope={process.env.REACT_APP_AUTH0_SCOPE}
          useRefreshTokens={process.env.REACT_APP_USE_REFRESH_TOKEN}
        >
          <Routes>

            {/* <Route exact path="/" element={<ProtectedRoute component={Dashboard} />} /> */}
            <Route exact path="/" element={<Navigate to="/assignments" />} />
            <Route path="/billing" element={<ProtectedRoute component={Billings} />} />
            <Route path="/organiser" element={<ProtectedRoute component={Organiser} />} />
            <Route path="/listings" element={<ProtectedRoute component={Listings} />} />
            <Route path="/orientations" element={<ProtectedRoute component={Orientations} />} />
            <Route exact path="/orientations/:orientationId" element={<ProtectedRoute component={Orientation} />} />
            <Route exact path="/listings/:listingId" element={<ProtectedRoute component={Listing} />} />
            <Route path="/assignments/all" element={<ProtectedRoute component={Assignments} />} />
            <Route path="/assignments" element={<ProtectedRoute component={AssignmentsCalendarView} />} />
            <Route exact path="/listing/:listingId/assignments" element={<ProtectedRoute component={AssignmentsByBooking} />} />
            <Route exact path="/listing/:listingId/assignments/:assignmentId/profile/:profileId" element={<ProtectedRoute component={Assignment} />} />
            <Route exact path="billing/assignments/invoice/:invoiceId" element={<ProtectedRoute component={AssignmentsByInvoice} />} />
            <Route path="/interests" element={<ProtectedRoute component={Interests} />} />
            <Route exact path="/listing/:listingId/interests" element={<ProtectedRoute component={InterestsByBooking} />} />
            <Route exact path="/listing/:listingId/interests/:interestId/profile/:profileId" element={<ProtectedRoute component={Interest} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Auth0ProviderWithRedirectCallback>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
      <ToastContainer
        hideProgressBar={true}
        pauseOnHover
        pauseOnFocusLoss
        icon={false}
        position="top-center"
      />
      <TawkMessengerReact
        propertyId="633488a354f06e12d897623f"
        widgetId="1ge2jbfc1" />
    </div>
  );
}

export default App;
