import React from 'react'
import Card from './Card'
import CreateOrientationModal from './CreateOrientationModal'
import Button from './Button'
import { useState } from 'react';
import Badge from './Badge'
import { getProviderValue } from "../util"

const OrientationDetailActionCard = ({ orientation, profiles }) => {
  const [viewModal, setViewModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const onCloseEditModal = () => setViewModal(false);
  const editBookingButtonClick = () => {
    setViewModal(true);
    setIsEditMode(true);
  }
  return (
    <Card>
      <div className="flex flex-col space-y-4">
        <span className='text-base font-bold'>Status</span>
        {orientation.is_active ? <Badge className="text-navyBlue bg-shadowBlue">Active</Badge> : <Badge className="text-black bg-badgeGrey">Inactive</Badge>}
        <div className='border mt-5'></div>
        <span className='text-base font-bold'>Actions </span>
        <Button className="border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white py-1.5 "
          onClick={editBookingButtonClick}>Edit</Button>
        <div className='border mt-5'></div>
        <span className='text-base font-bold'>Provider Types </span>
        <div className='flex flex-row flex-wrap gap-y-1 gap-x-2'>
          {orientation.licenses.map((license, index) => (
            <Badge className="bg-shadowBlue text-navyBlue" key={index}>{getProviderValue(profiles, license)}</Badge>
          ))}
        </div>
      </div>
      {viewModal && <CreateOrientationModal
        onClose={onCloseEditModal}
        isEditMode={isEditMode}
        editList={orientation}
        profiles={profiles}
      />}
    </Card>
  )
}

export default OrientationDetailActionCard