import React from 'react'
import { useParams } from 'react-router';
import TopBar from '../components/TopBar';
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import Title from "../components/Title";
import useOrientation from '../api/hooks/useOrientation';
import Layout from '../components/Layout';
import OrientationDetailActionCard from '../components/OrientationDetailActionCard';
import OrientationDetailCard from '../components/OrientationDetailCard';
import useProfileTypes from '../api/hooks/useProfileTypes';

const Orientation = () => {
  const { orientationId } = useParams();
  const { isLoading,
    isError,
    error,
    data: orientation } = useOrientation(orientationId);
  const {
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    data: profiles,
  } = useProfileTypes();
  let content = '';
  if (isLoading || isLoadingProfile) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (isErrorProfile) {
    content = <ErrorMessage errorMessage={errorProfile.message} />
  }
  if (orientation && profiles) {
    content = <>
      <Title title={`Orientation #${orientationId}`} />
      <div className="flex lg:flex-row md:w-full w-full lg:justify-between gap-y-10 md:flex-col-reverse flex-col-reverse justify-center">
        <div className='basis-[67%]'>
          <OrientationDetailCard orientation={orientation} />
        </div>
        <div className='basis-[30%]  h-full'>
          <OrientationDetailActionCard orientation={orientation} profiles={profiles} />
        </div>
      </div>
    </>
  }
  return (
    <Layout>
      <TopBar />
      {content}
    </Layout>
  );
}

export default Orientation