import React from 'react'
import PopOver from './PopOver';
import { FcCancel } from "react-icons/fc";
import withPermissionRequired from "../services/auth0";

const CancelListIcon = ({ handleDeleteClick }) => {
  return (
    <PopOver message={"Cancel"}><FcCancel className='hover:font-bold hover:cursor-grab text-red-600 h-7 w-7 hover:scale-125'
      onClick={handleDeleteClick} /></PopOver>
  )
}

export default withPermissionRequired(CancelListIcon, "update:listing")
