import React from 'react'
import { INTEREST_STATUS } from '../data/InterestStatus'
import Badge from './Badge'
import { useState } from 'react'
import ConfirmModal from './ConfirmModal'
import { useMutation, useQueryClient } from 'react-query';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { DAYS } from '../data/Days'
import Recurrence from './Recurrence'
import BackdropLoading from './BackdropLoading'
import Card from './Card'
import withPermissionRequired from "../services/auth0";
import InterestActions from './InterestActions'

const InterestDetailActionCard = ({ status, interestId, bookingId, setStatus, listingStatus, availability }) => {
  const [viewConfirmModal, setViewConfirmModal] = useState(false)
  const [action, setAction] = useState('')
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let content = '';
  const updateInterestStatus = useMutation(async (action) => {
    return axios.put(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/${bookingId}/interest/${interestId}/`, action, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'updateInterest',
    onSuccess: () => {
      queryClient.invalidateQueries('interests');
      queryClient.invalidateQueries('interestsByBooking');
      queryClient.invalidateQueries('interestLogs');
      if (action === "accept") {
        setStatus(10);
        toast.success(<ToastNotification message="Interest accepted successfully" status="success" />);
      }
      else {
        setStatus(20)
        toast.success(<ToastNotification message="Interest rejected successfully" status="success" />);
      }
      onClose();
    },
    onError: (error) => {
      if (error.response.data.custom)
        toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    }
  }
  );
  if (updateInterestStatus.isLoading) {
    content = <BackdropLoading />
  }
  const availableDays = availability.map((day) => day.day);
  const acceptInterestButtonClick = () => {
    setViewConfirmModal(true);
    setAction("accept");
  }
  const rejectInterestButtonClick = () => {
    setViewConfirmModal(true);
    setAction("reject");
  }
  const onClose = () => {
    setViewConfirmModal(false);
    setAction("");
  }
  const confirmModalHandler = (event) => {
    event.preventDefault();
    if (action === "accept") {
      updateInterestStatus.mutate({
        action: "pick"
      })
    }
    else
      updateInterestStatus.mutate({
        action: "reject"
      })
  }
  return (
    <Card>
      <div className="flex flex-col space-y-4">
        <span className='text-base font-bold'>Status </span>
        <Badge className={`${INTEREST_STATUS[status].css}`}>
          <span>{INTEREST_STATUS[status].value}</span>
        </Badge>
        {/* {listingStatus === 40 && <span className='text-red-600'>Listing is Fullfilled and not accepting any more interests</span>} */}
        {status === 0 && listingStatus === 20 && 
        <InterestActions acceptInterestButtonClick={acceptInterestButtonClick} rejectInterestButtonClick={rejectInterestButtonClick}/>}
          {/* <div className='border mt-5'></div>
          <span className='text-base font-bold'>Actions </span>
          <Button
            className="text-navyBlue bg-white border border-navyBlue py-1.5 hover:bg-navyBlue hover:text-white"
            onClick={acceptInterestButtonClick}
          > Accept Interest</Button>
          <Button className="text-navyBlue bg-white border border-navyBlue py-1.5 hover:bg-navyBlue hover:text-white"
            onClick={rejectInterestButtonClick}
          >Reject Interest</Button>  */}
      </div>
      <div className='border mt-5'></div>
      <h2 className='py-3 text-base font-bold'>Availability</h2>
      <div className='flex flex-row flex-wrap gap-y-1 gap-x-2'>
        {DAYS.map((day, index) => (
          <Recurrence day={day} key={index} recurrence={availableDays} />
        ))}
      </div>
      {/* <div className='border mt-5'></div>
      <h2 className='py-3 text-base font-bold'>Languages</h2>
      <div>English: native</div>
      <div>German: proficient</div> */}
      {viewConfirmModal && <ConfirmModal
        title={action === "accept" ? "Accept Interest" : "Reject Interest"}
        message={`Are you sure you want to ${action === "accept" ? "accept" : "reject"} this interest ?`}
        buttonName={action === "accept" ? "Yes, Accept" : "Yes, Reject"}
        onClose={onClose}
        confirmModalHandler={confirmModalHandler}
      />}
      {content}
    </Card>
  )
}

export default withPermissionRequired(InterestDetailActionCard, ["detail:interest","update:interest"], false)