import React from 'react'
import Badge from './Badge'
import { format } from 'date-fns'
import Recurrence from './Recurrence';
import { rrulestr } from 'rrule'
import moment from 'moment';
import { DAYS } from '../data/Days'
import Card from './Card';
import withPermissionRequired from "../services/auth0";
import { getProviderValue, getLabelFromValue, getCssFromValue } from "../util"
import parse from "html-react-parser";
import { LISTING_SELECTION_TYPE } from '../data/ListingSelectionType';

const ListingDetailCard = ({ list, profiles }) => {
  let recurrence = [];
  if (list.recurrence) {
    recurrence = rrulestr(list.recurrence).options;
  }
  return (
    <Card>
      <h2 className="text-xl xs:text-lg font-bold break-words mb-4">{list.title}</h2>
      {/* <p className="text-gray mt-4 break-words">{list.description}</p> */}
      {list.description && <span className="no-tailwindcss-base">
        {parse(list.description)}
      </span>}
      <div className='border mt-6'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">Start time</span>
          <div className='text-sm'>{moment(list.start_time).format('MMMM Do YYYY, hh:mm a')}</div>
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className="font-bold">End time</div>
          <div className='text-sm'>{moment(list.end_time).format('MMMM Do YYYY, hh:mm a')}</div>
        </div>
      </div>
      {list.recurrence && <>
        <div className="flex flex-row gap-x-24  gap-y-4 mt-7 flex-wrap">
          <div className='flex flex-col gap-y-2'>
            <span className="font-bold">Repeats every</span>
            <div className="flex flex-row gap-x-2 flex-wrap gap-y-2">
              {DAYS.map((day, index) => (
                <Recurrence day={day} key={index} recurrence={recurrence.byweekday} />
              ))}
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <span className="font-bold">Until</span>
            <span className="text-sm">{moment(recurrence.until).format('DD MMM YYYY')}</span>
          </div>
        </div>
      </>
      }
      <div className='border mt-5'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className='flex flex-col gap-y-2 items-center'>
          <span className="font-bold">Rate</span>
          <Badge className="bg-shadowBlue text-navyBlue">{(list.rate_per_hour / 100)} CAD</Badge>
        </div>
        <div className='flex flex-col gap-y-2 items-center'>
          <span className="font-bold">Type</span>
          <Badge className="bg-shadowBlue text-navyBlue">{getProviderValue(profiles, list.provider_type)}</Badge>
        </div>
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">No of resources</span>
          <Badge className="bg-shadowBlue text-navyBlue">{list.number_of_resources}</Badge>
        </div>
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">Listing selection type</span>
          <Badge className={getCssFromValue(LISTING_SELECTION_TYPE, list.listing_type)}>{getLabelFromValue(LISTING_SELECTION_TYPE, list.listing_type)}</Badge>
        </div>
      </div>
      {list.requires_orientation && <><div className='border mt-5'></div>
        <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
          <div className='flex flex-col gap-y-2'>
            <span className="font-bold">Requires orientation</span>
            <Badge className="bg-shadowBlue text-navyBlue">Yes</Badge>
          </div>
          <div className='flex flex-col gap-y-2'>
            <span className="font-bold">No of orientation sessions</span>
            <Badge className="bg-shadowBlue text-navyBlue">{list.number_of_orientation_assignments}</Badge>
          </div>
        </div></>}
      <div className='border mt-5'></div>
      {/* <div className='flex flex-col gap-y-2'>
      <div className="font-bold mt-4">Address</div>
      <span className='text-sm'>{list.address.full_address}</span>
      </div>
      <div className='flex flex-col gap-y-2'>
      <div className="font-bold mt-4">Last updated </div>
      <span className='text-sm'>{format(new Date(list.updated_on), "dd MMM yyyy hh:mm aaaaa'm'")}</span>
      </div> */}
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">Address</span>
          <div className='text-sm'>{list.address.facility_name}, {list.address.short_address}</div>
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className="font-bold">Last updated</div>
          <div className='text-sm'>{format(new Date(list.updated_on), "dd MMM yyyy hh:mm aaaaa'm'")}</div>
        </div>
      </div>
      <div className='border mt-5'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">Accepting interest until</span>
          <div className='text-sm'>{moment(list.accepting_until).format('MMMM Do YYYY, hh:mm a')}</div>
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className="font-bold">Expires on </div>
          <div className='text-sm'>{moment(list.expiration_date).format('MMMM Do YYYY, hh:mm a')}</div>
        </div>
      </div>
      <div className='border mt-5'></div>
      <div className="flex flex-row gap-x-32  gap-y-4 mt-4 flex-wrap">
        <div className='flex flex-col gap-y-2'>
          <span className="font-bold">Created by</span>
          <div className='text-sm'>{list.creator.user.full_name}</div>
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className="font-bold">Created on</div>
          <div className='text-sm'>{moment(list.created_on).format('MMMM Do YYYY, hh:mm a')}</div>
        </div>
      </div>
    </Card>
  )
}

export default withPermissionRequired(ListingDetailCard, ["detail:listing"], false)