import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const SideBarItems = (props) => {
  const location = useLocation();
  return (
    <Link to={props.href} className={`flex items-center p-2 xs:p-1 text-base text-grey rounded-lg hover:bg-shadowBlue hover:text-navyBlue hover:font-bold
    ${location.pathname.includes(props.href) ? !(location.pathname === "/") ? !(props.href === "/") ? "bg-shadowBlue text-[#651FFFFF] font-bold" : "text-Grey"
        : props.href === "/" ? "bg-shadowBlue text-[#651FFFFF] font-bold" : "text-Grey" : "text-Grey"}`}>
      {props.icon}
      {/* <span className="ml-3 hidden lg:block md:hidden">{props.title}</span> */}
      <span className="ml-3 tabletSidebar:block tabletSidebar_md:block tabletSidebar_lg:hidden tabletSidebar_sm:hidden  block xs:hidden">{props.title}</span>
    </Link>
  )
}

export default SideBarItems