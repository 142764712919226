import React from 'react'
import SideBar from "../components/SideBar";

const Layout = (props) => {
  return (
    <div className="flex flex-row h-screen">
      <SideBar />
      <div className="flex-1 xs:p-2 p-7 bg-backgroungGrey overflow-auto justify-center h-screen">
      <div className="mx-auto max-w-[1800px] justify-center">
        {props.children} 
        </div>       
      </div>
    </div>
  )
}

export default Layout