import React from 'react'
import { useState } from 'react'
import BackdropLoading from './BackdropLoading';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { RRule } from 'rrule'
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { rrulestr } from 'rrule'
import { WEEKDAYS } from '../data/Weekdays';
import { LISTING_SELECTION_TYPE } from '../data/ListingSelectionType';
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import ModalWithPagination from './ModalWithPagination';
import RadioButton from './RadioButton';
import CheckBox from './CheckBox';
import { getProviderValue } from "../util"
import { MdOutlineFactCheck } from "react-icons/md";
import { GoInfo } from "react-icons/go";
import PopOver from './PopOver';
import ListingDaysModal from './ListingDaysModal';
import TinyMceEditor from './TinyMceEditor';

function CreateBookingModal(props) {

  const getBaseRate = (type) => {
    let baseRate = 0
    props.rates.forEach((rate) => {
      if (type === rate.license_type)
        baseRate = rate.rate_per_hour;
    })
    return baseRate / 100;
  };

  const rateStrings = {
    '10': 'Karer Est. 65-75 CAD/hr',
    '20': 'Karer Est. 55-70 CAD/hr',
    '30': 'Karer Est. 45-62 CAD/hr',
    '40': 'Karer Est. 25-35 CAD/hr',
    '50': 'Karer Est. 25-30 CAD/hr',
  }

  const getRateString = (type) => {
    return rateStrings[type]
  };
  const [modalPage, setModalPage] = useState(1);
  const [showListingDaysModal, setShowListingDaysModal] = useState(false);
  const [listingDays, setListingDays] = useState([]);
  // const [title, setTitle] = useState(() => {
  //   const initialState = props.isEditMode ? props.editList.title : "";
  //   return initialState;
  // });
  const [description, setDescription] = useState(() => {
    const initialState = props.isEditMode ? props.editList.description === null ? "" : props.editList.description : "";
    return initialState;
  });
  const [startTime, setStartTime] = useState(() => {
    const initialState = props.isEditMode ? moment(props.editList.start_time).format('yyyy-MM-DDTHH:mm') : "";
    return initialState;
  });
  const [endTime, setEndTime] = useState(() => {
    const initialState = props.isEditMode ? moment(props.editList.end_time).format('yyyy-MM-DDTHH:mm') : "";
    return initialState;
  });
  const [providerType, setProviderType] = useState(() => {
    const initialState = props.isEditMode ? String(props.editList.provider_type) : props.profiles[0].key;
    return initialState;
  });
  const [noOfResources, setNoOfResources] = useState(() => {
    const initialState = props.isEditMode ? props.editList.number_of_resources : 1;
    return initialState;
  });
  const [ratePerHour, setRatePerHour] = useState(() => {
    const initialState = props.isEditMode ? (props.editList.rate_per_hour) / 100 : getBaseRate(props.profiles[0].key);
    return initialState;
  });

  const [rateHelpText, setRateHelpText] = useState(() => {
    const initialState = getRateString(props.profiles[0].key);
    return initialState;
  });
  const [addressOfInstitution, setAddressOfInstitution] = useState(() => {
    const initialState = props.isEditMode ? props.editList.address.id : props.address[0].id;
    return initialState;
  });
  const [selectedShift, setSelectedShift] = useState(() => {
    let shiftValue = 0
    if (props.isEditMode) {
      let timeDiference = parseInt((moment.duration(moment(props.editList.end_time).diff(moment(props.editList.start_time)))).asHours())
      shiftValue = (timeDiference === 8 || timeDiference === 6 || timeDiference === 4) ? timeDiference : 0;
    }
    const initialState = props.isEditMode ? shiftValue : 8;
    return initialState;
  });
  const [otherShiftDuration, setOtherShiftDuration] = useState(() => {
    let shiftValue = 0
    if (props.isEditMode) {
      let timeDiference = parseInt((moment.duration(moment(props.editList.end_time).diff(moment(props.editList.start_time)))).asHours())
      shiftValue = (timeDiference === 8 || timeDiference === 6 || timeDiference === 4) ? "" : timeDiference;
    }
    const initialState = props.isEditMode ? shiftValue : "";
    return initialState;
  });
  // const [selectedDays, setSelectedDays] = useState(() => {
  //   let recurrence = [];
  //   if (props.editList.recurrence) {
  //     recurrence = ((rrulestr(props.editList.recurrence)).options.byweekday).map((rec) => ({ value: WEEKDAYS[rec].value, label: WEEKDAYS[rec].label }));
  //   }
  //   const initialState = props.isEditMode ? recurrence : [];
  //   return initialState;
  // });
  const [selectedDays, setSelectedDays] = useState(() => {
    let recurrence;
    if (props.editList.recurrence) {
      recurrence = WEEKDAYS.map((weekDay) => ((rrulestr(props.editList.recurrence)).options.byweekday).includes(weekDay.id)
        ? { ...weekDay, checked: true } : weekDay)
    }
    else
      recurrence = WEEKDAYS;
    const initialState = props.isEditMode ? recurrence : WEEKDAYS;
    return initialState;
  });
  const [isChecked, setIsChecked] = useState(() => {
    const initialState = props.isEditMode ? (props.editList.recurrence ? true : false) : false;
    return initialState;
  });
  const [isCheckedOrientation, setIsCheckedOrientation] = useState(() => {
    const initialState = props.isEditMode ? (props.editList.requires_orientation ? true : false) : true;
    return initialState;
  });
  const [noOfOrientation, setNoOfOrientation] = useState(() => {
    const initialState = props.isEditMode ? props.editList.number_of_orientation_assignments : 1;
    return initialState;
  });
  const [untilDay, setUntilDay] = useState(() => {
    let day = "";
    if (props.editList.recurrence) {
      day = (rrulestr(props.editList.recurrence)).options.until
    }
    const initialState = props.isEditMode ? moment(day).format('yyyy-MM-DD') : "";
    return initialState;
  });
  const [selectedProviderSelection, setSelectedProviderSelection] = useState(() => {
    const initialState = props.isEditMode ? props.editList.listing_type : 10;
    return initialState;
  });

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  const location = useLocation();
  const updateKey = location.pathname === "/listings" ? "listings" : "listing"
  let content = '';
  const addList = useMutation(async (newList) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'booking',
    onSuccess: () => {
      queryClient.invalidateQueries("listings");
      props.onClose();
      toast.success(<ToastNotification message="Booking created successfully" status="success" />);
    },
    onError: (error) => {
      // if (error.response.data.custom)
      //   toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      // else
      //   toast.warn(<ToastNotification message={error.message} status="warn" />);
      if (error.response.status === 400) {
        if (typeof (error.response.data) === "object") {
          if (Object.keys(error.response.data).length > 0) {
            var key = Object.keys(error.response.data)[0];
            if (key.length > 0)
              toast.warn(<ToastNotification message={error.response.data[key][0]} status="warn" />);
            else
              toast.warn(<ToastNotification message={error.message} status="warn" />);
          }
          else
            toast.warn(<ToastNotification message={error.message} status="warn" />);
        }
        else
          toast.warn(<ToastNotification message={error.message} status="warn" />);
      }
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );

  const updateList = useMutation(async (newList) => {
    return axios.put(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/${props.editList.id}/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'bookingUpdate',
    onSuccess: () => {
      queryClient.invalidateQueries(updateKey);
      queryClient.invalidateQueries('listingLogs');
      props.onClose();
      toast.success(<ToastNotification message="Booking updated successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        if (typeof (error.response.data) === "object") {
          if (Object.keys(error.response.data).length > 0) {
            var key = Object.keys(error.response.data)[0];
            if (key.length > 0)
              toast.warn(<ToastNotification message={error.response.data[key][0]} status="warn" />);
            else
              toast.warn(<ToastNotification message={error.message} status="warn" />);
          }
          else
            toast.warn(<ToastNotification message={error.message} status="warn" />);
        }
        else
          toast.warn(<ToastNotification message={error.message} status="warn" />);
      }
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );

  const getListingDays = useMutation(async (newList) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/recurrence/`, newList, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'listingDays',
    onSuccess: (result) => {
      setListingDays(result.data)
    },
    onError: (error) => {
      // if (error.response.data.custom)
      //   toast.warn(<ToastNotification message={error.response.data.custom[0]} status="warn" />);
      // else
      //   toast.warn(<ToastNotification message={error.message} status="warn" />);
      if (error.response.status === 400) {
        if (typeof (error.response.data) === "object") {
          if (Object.keys(error.response.data).length > 0) {
            var key = Object.keys(error.response.data)[0];
            if (key.length > 0)
              toast.warn(<ToastNotification message={error.response.data[key][0]} status="warn" />);
            else
              toast.warn(<ToastNotification message={error.message} status="warn" />);
          }
          else
            toast.warn(<ToastNotification message={error.message} status="warn" />);
        }
        else
          toast.warn(<ToastNotification message={error.message} status="warn" />);
      }
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );
  if (getListingDays.isLoading) {
    content = <BackdropLoading />
  }
  if (addList.isLoading) {
    content = <BackdropLoading />
  }
  if (updateList.isLoading) {
    content = <BackdropLoading />
  }

  let rule = null;
  // const days = [
  //   { value: RRule.MO, label: 'Monday' },
  //   { value: RRule.TU, label: 'Tuesday' },
  //   { value: RRule.WE, label: 'Wednesday' },
  //   { value: RRule.TH, label: 'Thursday' },
  //   { value: RRule.FR, label: 'Friday' },
  //   { value: RRule.SA, label: 'Saturday' },
  //   { value: RRule.SU, label: 'Sunday' },
  // ];
  const shiftOptions = [
    { value: 8, label: '8hrs' },
    { value: 6, label: '6hrs' },
    { value: 4, label: '4hrs' },
    { value: 0, label: 'Other' }
  ];
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleOrientationOnChange = () => {
    setIsCheckedOrientation(!isCheckedOrientation);
  };
  const startTimeChangeHandler = (event) => {
    setStartTime(event.target.value);
    if (Number(selectedShift) === 8 || Number(selectedShift) === 6 || Number(selectedShift) === 4)
      setEndTime(moment(event.target.value).add(Number(selectedShift), 'hours').format('yyyy-MM-DDTHH:mm'))
    if (Number(selectedShift) === 0 && otherShiftDuration > 0)
      setEndTime(moment(event.target.value).add(Number(otherShiftDuration), 'hours').format('yyyy-MM-DDTHH:mm'))
  };
  const endTimeChangeHandler = (event) => {
    setEndTime(event.target.value);
    if (startTime) {
      let timeDiference = parseInt((moment.duration(moment(event.target.value).diff(moment(startTime)))).asHours())
      if (!(timeDiference === 8 || timeDiference === 6 || timeDiference === 4)) {
        setSelectedShift(0)
        if (timeDiference > 0)
          setOtherShiftDuration(timeDiference)
      }
      else {
        setSelectedShift(timeDiference)
        setOtherShiftDuration("")
      }
    }
  };
  const untilDateChangeHandler = (event) => {
    setUntilDay(event.target.value);
  };
  const providerChangeHandler = (event) => {
    setProviderType(event.target.value);
    setRatePerHour(getBaseRate(Number(event.target.value)))
    setRateHelpText(getRateString(Number(event.target.value)))
  };
  const resourceCountChangeHandler = (event) => {
    setNoOfResources(event.target.value);
  };
  const otherShiftDurationChangeHandler = (event) => {
    setOtherShiftDuration(event.target.value);
    setSelectedShift(0);
    if (startTime && Number(event.target.value) > 0)
      setEndTime(moment(startTime).add(Number(event.target.value), 'hours').format('yyyy-MM-DDTHH:mm'))
  };
  const orientationCountChangeHandler = (event) => {
    setNoOfOrientation(event.target.value);
  };
  const rateChangeHandler = (event) => {
    setRatePerHour(event.target.value);
  };

  const addressChangeHandler = (event) => {
    setAddressOfInstitution(event.target.value);
  };

  const providerSelectionChangeHandler = (event) => {
    setSelectedProviderSelection(event.target.value)
  }
  const nextButtonClick = () => {
    if (noOfResources < 1) {
      toast.warn(<ToastNotification message="Please enter valid resource count" status="warn" />);
      return;
    }
    if (ratePerHour < getBaseRate(Number(providerType))) {
      toast.warn(<ToastNotification message={`Please enter a valid rate. Rate should be greater than ${getBaseRate(Number(providerType))} CAD`} status="warn" />);
      return;
    }
    if (isCheckedOrientation && noOfOrientation < 1) {
      toast.warn(<ToastNotification message="Please enter valid orientation session count" status="warn" />);
      return;
    }
    setModalPage(2);
  };
  const showListingDays = () => {
    if (startTime.trim().length === 0) {
      toast.warn(<ToastNotification message="Please enter valid start time" status="warn" />);
      return;
    }
    if (moment(startTime).isAfter(moment().add(6, 'M').subtract(1, 'd'))) {
      toast.warn(<ToastNotification message="Start time should be within 6 months" status="warn" />);
      return;
    }
    if (endTime.trim().length === 0) {
      toast.warn(<ToastNotification message="Please enter valid end time" status="warn" />);
      return;
    }
    if (!isChecked) {
      toast.warn(<ToastNotification message="Repeating is not selected" status="warn" />);
      return;
    }
    if (isChecked && selectedDays.filter(day => day.checked).length === 0) {
      toast.warn(<ToastNotification message="Please select repeating days" status="warn" />);
      return;
    }
    if (isChecked && (untilDay.trim().length === 0 || moment(untilDay).isBefore(startTime))) {
      toast.warn(<ToastNotification message="Please enter valid recurrence until date. It should be after start date" status="warn" />);
      return;
    }
    if (isChecked && moment(untilDay).isAfter(moment().add(6, 'M'))) {
      toast.warn(<ToastNotification message="Recurrence dates should be within 6 months" status="warn" />);
      return;
    }
    if (isChecked) {
      const days = selectedDays.filter(day => day.checked).map(day => day.value);
      rule = new RRule({
        freq: RRule.WEEKLY,
        byweekday: days,
        until: untilDay + ' ' + moment(startTime).format('HH:mm'),
      })
    }
    const recurrence = isChecked && rule ? rule.toString() : null;
    getListingDays.mutate({
      address: addressOfInstitution,
      start_time: new Date(startTime),
      end_time: new Date(endTime),
      recurrence: recurrence
    })
    setShowListingDaysModal(true)
  };

  const bookingHandler = (event) => {
    event.preventDefault();
    // if (title.trim().length === 0 || title.trim().length < 5) {
    //   toast.warn(<ToastNotification message="Please enter valid title (more than 5 characters)" status="warn" />);
    //   return;
    // }
    // if (description.trim().length === 0 || description.trim().length < 10) {
    //   toast.warn(<ToastNotification message="Please enter valid description (more than 10 characters)" status="warn" />);
    //   return;
    // }
    if (noOfResources < 1) {
      toast.warn(<ToastNotification message="Please enter valid resource count" status="warn" />);
      return;
    }
    if (ratePerHour < getBaseRate(Number(providerType))) {
      toast.warn(<ToastNotification message={`Please enter valid rate. Rate should be greater than ${getBaseRate(Number(providerType))} CAD`} status="warn" />);
      return;
    }
    if (startTime.trim().length === 0) {
      toast.warn(<ToastNotification message="Please enter valid start time" status="warn" />);
      return;
    }
    if (moment(startTime).isAfter(moment().add(6, 'M').subtract(1, 'd'))) {
      toast.warn(<ToastNotification message="Start time should be within 6 months" status="warn" />);
      return;
    }
    if (endTime.trim().length === 0) {
      toast.warn(<ToastNotification message="Please enter valid end time" status="warn" />);
      return;
    }
    if (isChecked && selectedDays.filter(day => day.checked).length === 0) {
      toast.warn(<ToastNotification message="Please select repeating days" status="warn" />);
      return;
    }
    if (isChecked && (untilDay.trim().length === 0 || moment(untilDay).isBefore(startTime))) {
      toast.warn(<ToastNotification message="Please enter valid recurrence until date. It should be after start date" status="warn" />);
      return;
    }
    if (isChecked && moment(untilDay).isAfter(moment().add(6, 'M'))) {
      toast.warn(<ToastNotification message="Recurrence until date should be within 6 months" status="warn" />);
      return;
    }
    if (isChecked) {
      //   const days = selectedDays.map((day) => {
      //     return day.value
      //   });
      const days = selectedDays.filter(day => day.checked).map(day => day.value);
      rule = new RRule({
        freq: RRule.WEEKLY,
        byweekday: days,
        until: untilDay + ' ' + moment(startTime).format('HH:mm'),
      })
    }
    const recurrence = isChecked && rule ? rule.toString() : null;
    const data = {
      description: description.trim().length === 0 ? null : description,
      address: addressOfInstitution,
      start_time: new Date(startTime),
      end_time: new Date(endTime),
      provider_type: providerType,
      rate_per_hour: ratePerHour * 100,
      recurrence: recurrence,
      number_of_resources: noOfResources,
      requires_orientation: isCheckedOrientation,
      number_of_orientation_assignments: !isCheckedOrientation ? 1 : noOfOrientation,
      listing_type: selectedProviderSelection
    }
    if (props.isEditMode) {
      updateList.mutate(data)
    }
    else {
      addList.mutate(data)
    }
  }
  const checkboxChangeHandler = (index) => {
    setSelectedDays(
      selectedDays.map((selectedDay, currentIndex) =>
        currentIndex === index
          ? { ...selectedDay, checked: !selectedDay.checked }
          : selectedDay
      )
    )
  }
  const onClose = () => setShowListingDaysModal(false);
  return (<>
    <ModalWithPagination
      title={props.isEditMode ? "Edit Listing" : "New Listing"}
      buttonName={props.isEditMode ? "Update" : "Create"}
      onClose={props.onClose}
      modalPage={modalPage}
      nextButtonClick={nextButtonClick}
      setModalPage={setModalPage}
      onSubmit={bookingHandler} >
      <div className='p-6 space-y-8 xs:space-y-6'>
        {modalPage === 1 && <>
          {props.address.length > 1 &&
            <div className='text-sm'>
              <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-600">Location</label>
              <select id="address" value={addressOfInstitution} onChange={addressChangeHandler}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                {props.address.map((adr) => (
                  <option key={adr.id} value={adr.id} >{adr.facility_name}, {adr.full_address} </option>)
                )}
              </select>
            </div>}
          <div className='flex lg:flex-row md:flex-row flex-col gap-y-4 justify-between'>
            <div className='text-sm'>
              <label htmlFor="provider" className="block mb-2 text-sm font-medium text-gray-600">Provider Type</label>
              <select id="provider" value={providerType} onChange={providerChangeHandler}
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${props.isEditMode ? "bg-gray-200" : "bg-white"}`}
                disabled={props.isEditMode ? true : false}>
                {props.rates.map((rate) => (
                  <option key={rate.license_type} value={rate.license_type} >{getProviderValue(props.profiles, rate.license_type)}</option>)
                )}
              </select>
            </div>
            <div>
              <label htmlFor="rate" className="block mb-2 text-sm font-medium text-gray-600">Rate per hour (CAD)</label>
              <input type="number" id="rate" value={ratePerHour}
                min={getBaseRate(Number(providerType))}
                onChange={rateChangeHandler}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="CAD" required />
              <div className='flex flex-row'>
                <p id="helper-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-400 mr-1">{rateHelpText}</p>
                <PopOver className="basis-[10%]" position="-left-48" message={`Our estimated market rate for this provider in your location.`}>
                  <GoInfo className='hover:cursor-pointer w-4 h-4 mb-2 hover:text-navyBlue' />
                </PopOver>
              </div>

            </div>
            <div>
              <label htmlFor="resources" className="block mb-2 text-sm font-medium text-gray-600">No of resources</label>
              <input type="number" min="1" id="resources" value={noOfResources}
                onChange={resourceCountChangeHandler}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="No of resources" required />
            </div>
          </div>
          <div>
            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-600">Notes (optional)</label>
            <TinyMceEditor height={200} description={description} setDescription={setDescription} />
          </div>
          {props.organiser.institution.can_autofill === true && <div>
            <div className='flex flex-row gap-x-2'>
              <h3 class="mb-3 text-sm font-medium text-gray-600 whitespace-nowrap">How do you wish to pick the provider?</h3>
              <PopOver className="basis-[10%]" position="-left-48" message="Select the Manual option to review applicant profiles before assigning them to a shift. If you choose Autofill, the shift will be automatically assigned to the first professional who expresses interest in the listing.">
                <GoInfo className='hover:cursor-pointer w-4 h-4 mb-2 hover:text-navyBlue' />
              </PopOver>
            </div>
            <ul class="flex flex-row gap-x-3 text-sm font-medium text-gray-600">
              {LISTING_SELECTION_TYPE.map((providerSelectionType, index) => (
                <li key={index}>
                  <input type="radio" id={providerSelectionType.label} name="hosting" value={providerSelectionType.value} class="hidden peer" checked={providerSelectionType.value === Number(selectedProviderSelection)} onChange={providerSelectionChangeHandler} />
                  <label for={providerSelectionType.label} class="inline-flex justify-between items-center py-1 px-2 bg-white rounded-lg border hover:border-shadowBlue border-gray-200 cursor-pointer peer-checked:border-navyBlue peer-checked:text-navyBlue hover:text-shadowBlue hover:bg-gray-50">
                    <div class="block">
                      <span>{providerSelectionType.label}</span>
                    </div>
                  </label>
                </li>))}
            </ul>
          </div>}
          <div className='flex lg:flex-row md:flex-row flex-col gap-y-1 gap-x-14'>
            <div className="flex flex-row space-x-3 mb-4 basis-[30%] mt-2">
              <input id="requireOrientation" type="checkbox" value="" checked={isCheckedOrientation}
                onChange={handleOrientationOnChange}
                className="w-4 h-4 text-navyBlue bg-gray-100 rounded border-gray-300 focus:ring-navyBlue" />
              <label htmlFor="repeats" className=" text-sm font-medium text-gray-600 whitespace-nowrap">Requires Orientation</label>
            </div>
            {isCheckedOrientation && <div className='basis-[60%] flex flex-row space-x-6'>
              <label htmlFor="resources" className="block mb-2 text-sm font-medium text-gray-600 mt-2 whitespace-nowrap">No of Orientation Sessions</label>
              <input type="number" min="1" id="resources" value={noOfOrientation}
                onChange={orientationCountChangeHandler}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="No of resources" required />
            </div>}
          </div>

        </>}
        {modalPage === 2 && <>
          {/* <div className="flex lg:flex-row md:flex-row flex-col lg:gap-x-1 md:gap-x-1  gap-y-4 "> */}
          <div className="">
            <label htmlFor="startTime" className="block mb-2 text-sm font-medium text-gray-600">Start time</label>
            <input type="datetime-local" id="startTime" min={moment(new Date()).format('yyyy-MM-DDTHH:mm')} value={startTime}
              onChange={startTimeChangeHandler}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="start time" required />
          </div>
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-600">Shift duration</label>
            <div className='flex flex-row xs:flex-col xs:gap-y-4 gap-x-5'>
              <div className='flex flex-row gap-x-5'>
                {shiftOptions.map((shiftOption, index) => (
                  <RadioButton key={index} value={shiftOption.value}
                    label={shiftOption.label}
                    selectedShift={selectedShift}
                    setSelectedShift={setSelectedShift}
                    setEndTime={setEndTime}
                    startTime={startTime}
                    setOtherShiftDuration={setOtherShiftDuration}
                    otherShiftDuration={otherShiftDuration}
                  />)
                )}
              </div>
              <input type="number" id="other" step="0.01" min="1" max="10" presicion={2} value={otherShiftDuration}
                onChange={otherShiftDurationChangeHandler}
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-navyBlue focus:border-navyBlue block  w-full p-2.5" placeholder="custom shift duration" />
            </div>
          </div>
          <div className=''>
            <label htmlFor="endtTime" className="block mb-2 text-sm font-medium text-gray-600">End time</label>
            <input type="datetime-local" id="endTime" min={moment(new Date()).format('yyyy-MM-DDTHH:mm')} value={endTime}
              onChange={endTimeChangeHandler}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  w-full p-2.5" placeholder="end time" required />
          </div>
          <div>
            <div className="flex items-center flex-row space-x-3 mb-4">
              <input id="repeats" type="checkbox" value="" checked={isChecked}
                onChange={handleOnChange}
                className="w-4 h-4 text-navyBlue bg-gray-100 rounded border-gray-300 focus:ring-navyBlue" />
              <label htmlFor="repeats" className="ml-2 text-sm font-medium text-gray-600">Repeats</label>
            </div>
            {isChecked && <><div className='text-sm flex flex-row space-x-8 mb-4'>
              <label htmlFor="days" className=" text-sm font-medium text-gray-600 basis-2 mt-1">Every</label>
              {/* <MultiSelect
              options={days}
              value={selectedDays}
              onChange={setSelectedDays}
              className='w-full'
            /> */}
              <div className="flex flex-row gap-x-2 flex-wrap gap-y-2">
                {selectedDays.map((day, index) => (
                  <CheckBox
                    key={index}
                    isChecked={day.checked}
                    checkboxChangeHandler={() => checkboxChangeHandler(index)}
                    label={day.label}
                  />
                ))}</div>
            </div>
              <div className='flex flex-row space-x-6'>
                <label htmlFor="date" className="block text-sm font-medium text-gray-600 mt-3">Until</label>
                <input type="date" id="untildate" min={new Date().toISOString().split('T')[0]} value={untilDay}
                  onChange={untilDateChangeHandler}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="date" required />
                {isChecked && selectedDays.filter(day => day.checked).length !== 0
                  && untilDay.trim().length !== 0 && startTime.trim().length !== 0 && endTime.trim().length !== 0
                  && moment(startTime).isBefore(untilDay) && <PopOver message={"Verify dates"}>
                    <MdOutlineFactCheck className='text-gray-600 hover:text-navyBlue hover:cursor-grab h-5 w-5 hover:scale-125' onClick={showListingDays} />
                  </PopOver>}
              </div>
            </>}</div>
        </>}
      </div>
    </ModalWithPagination>
    {showListingDaysModal && <ListingDaysModal listingDates={listingDays} title={"Listing dates"} onClose={onClose} />}
    {content}</>
    // </Modal>
  );
}

export default CreateBookingModal