import React from 'react'
import Button from './Button'
import { useState } from 'react';
import DeleteBookingModal from './DeleteBookingModal';
import CreateBookingModal from './CreateBookingModal';
import { useNavigate } from "react-router-dom";
import NotificationBadge from './NotificationBadge';
import { STATUS } from '../data/Status';
import Badge from './Badge';
import Card from './Card';
import withPermissionRequired from "../services/auth0";
import ListActions from './ListActions';

const ListingDetailActionCard = ({ list, address, profiles, rates, organiser }) => {
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  let navigate = useNavigate();
  const onCloseDeleteModal = () => setViewDeleteModal(false)
  const onCloseEditModal = () => setViewModal(false);
  const editBookingButtonClick = () => {
    setViewModal(true);
    setIsEditMode(true);
  }
  return (
    <Card>
      <div className="flex flex-col space-y-4">
        <span className='text-base font-bold'>Status </span>
        <Badge className={`${STATUS[list.status].css}`}>
          <span>{STATUS[list.status].value}</span>
        </Badge>
        <div className='border mt-5'></div>
        <ListActions canEdit={list.can_edit} canCancel={list.can_cancel}
          editBookingButtonClick={editBookingButtonClick} setViewDeleteModal={setViewDeleteModal} />
        {/* {(list.can_edit || list.can_cancel) &&  <><span className='text-base font-bold'>Actions </span>
        {list.can_edit && <EditListButton editBookingButtonClick={editBookingButtonClick}/>}
        {list.can_cancel && <CancelListButton setViewDeleteModal={setViewDeleteModal}/>}
           <div className='border mt-5'></div> </>} */}
        <span className='text-base font-bold'>Related</span>
        <NotificationBadge count={list.assignment_count}>
          <Button className="border border-navyBlue bg-white hover:bg-navyBlue text-navyBlue hover:text-white py-1.5 w-full"
            onClick={() => navigate(`/listing/${list.id}/assignments`)}>Assignments</Button></NotificationBadge>
        <NotificationBadge count={list.interest_count}>
          <Button className="border border-navyBlue bg-white hover:bg-navyBlue text-navyBlue hover:text-white py-1.5 w-full"
            onClick={() => navigate(`/listing/${list.id}/interests`)}>Interests</Button></NotificationBadge>
      </div>
      {viewDeleteModal && <DeleteBookingModal
        onClose={onCloseDeleteModal}
        list={list}
      />}
      {viewModal && <CreateBookingModal
        onClose={onCloseEditModal}
        isEditMode={isEditMode}
        editList={list}
        address={address}
        profiles={profiles}
        rates={rates}
        organiser={organiser}
      />}
    </Card>
  )
}

export default withPermissionRequired(ListingDetailActionCard, ["detail:listing", "update:listing"], false)