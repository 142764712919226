import React from 'react'
import Badge from './Badge';
import { STATUS } from '../data/Status';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import DeleteBookingModal from './DeleteBookingModal';
import CancelListIcon from './CancelListIcon';
import EditListIcon from './EditListIcon';
import { getProviderValue } from "../util"
import { BsPeopleFill } from "react-icons/bs";
import { MdAssignmentInd } from "react-icons/md";
import NotificationBadge from './NotificationBadge';
import PopOver from './PopOver';

const ListingTableRows = ({ list, editBookingButtonClick, setEditList, profiles }) => {
  const navigate = useNavigate();
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const onClose = () => setViewDeleteModal(false)

  const handleInterestClick = (event) => {
    event.stopPropagation();
   navigate(`/listing/${list.id}/interests`)
  }
  const handleAssignmentClick = (event) => {
    event.stopPropagation();
   navigate(`/listing/${list.id}/assignments`)
  }
  const handleEditClick = (event) => {
    event.stopPropagation();
    setEditList(list);
    editBookingButtonClick();
  }
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setViewDeleteModal(true)
  }
  return (
    <>
      <tr className="border-b border-grey odd:bg-white even:bg-slate-50 hover:cursor-grab hover:bg-violet-100"
        onClick={() => navigate(`/listings/${list.id}`)}>
        {/* <td className="px-6 py-4 whitespace-nowrap text-gray-900 w-max-1 font-bold text-sm">
          {list.parent !== null && <Badge className="bg-amber-50 text-rose-900">System</Badge>} {list.title.substring(0, 20)}{list.title.length > 20 && "..."}
        </td> */}
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap"> {list.parent !== null && <Badge className="bg-amber-50 text-rose-900 mr-1">System</Badge>}
          <Badge className="bg-shadowBlue text-navyBlue">{getProviderValue(profiles, list.provider_type)}</Badge></td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          <Badge className={STATUS[list.status].css}>{STATUS[list.status].value}</Badge>
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          {moment(list.start_time).format('MMMM Do YYYY, hh:mm a')}
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          {list.recurrence ? <Badge className="text-navyBlue bg-shadowBlue">Yes</Badge> : <Badge className="text-black bg-badgeGrey">No</Badge>}
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          {(list.can_cancel || list.can_edit) ? <> <div className='flex space-x-3'>
            {list.can_cancel && <CancelListIcon handleDeleteClick={handleDeleteClick} />}
            {list.can_edit && <EditListIcon handleEditClick={handleEditClick} />}
          </div></> : "No actions"}
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
        <div className='flex space-x-6'>
          <PopOver message="Interests">
          <NotificationBadge className="text-xs px-2 xs:px-1.5 xs:py-0.5 hover:cursor-grab"
          onClick={handleInterestClick} count={list.interest_count} position="top-0  left-2">
        <BsPeopleFill className="h-7 w-7 text-navyBlue hover:scale-125 hover:cursor-grab" onClick={handleInterestClick}/>
        </NotificationBadge>
        </PopOver>
        <PopOver message="Assignments">
          <NotificationBadge className="text-xs px-2 xs:px-1.5 xs:py-0.5 hover:cursor-grab"
          onClick={handleAssignmentClick} count={list.assignment_count} position="top-0  left-2">
        <MdAssignmentInd className="h-7 w-7 text-navyBlue hover:scale-125 hover:cursor-grab" onClick={handleAssignmentClick}/>
        </NotificationBadge>
        </PopOver>
        </div>
        </td>
      </tr>
      {viewDeleteModal && <DeleteBookingModal
        onClose={onClose}
        list={list}
      />}
    </>
  )
}

export default ListingTableRows