import React from 'react'
import NotificationItems from './NotificationItems';
import Button from './Button';

const Drawer = ({ setShowNotification, showNotification, hasMore, items, notificationPage, setNotificationPage, unReadMessages, setHasMoreClicked }) => {
  const handleLoadMore = () => {
    setNotificationPage(notificationPage + 1)
    setHasMoreClicked(true)
  }
  const handleDrawerClose = () => {
    setShowNotification(false);
    setNotificationPage(1)
    localStorage.setItem("time", items[0].created_on);
  }
  return (
    <main
      className={
        " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 backdrop-brightness-50 inset-0 transform ease-in-out " +
        (showNotification
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " w-screen max-w-sm right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (showNotification ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-screen max-w-sm flex flex-col space-y-4 overflow-y-auto h-full py-2">
          <div className="flex justify-between items-start p-4 xs:py-2 rounded-t border-b ">
            <header className="font-bold text-lg">Notifications</header>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDrawerClose}  >
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <ul>
            {items.map((notification, index) => (
              <li key={index} className={`px-4 ${unReadMessages.includes(notification) && "text-navyBlue"}`}>
                {/* <div>{index}</div> */}
                <NotificationItems notification={notification} />
              </li>
            ))}
          </ul>
          {hasMore && <Button className="w-fit ml-32 px-2 py-1" onClick={handleLoadMore}>Load more</Button>}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={handleDrawerClose}
      ></section>
    </main>
  )
}

export default Drawer