import React from 'react'
import dummyImage from "../data/dummyImage.jpeg"
import { GrLocation } from "react-icons/gr";
import { FaStar } from "react-icons/fa";
import Badge from './Badge';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { PROVIDER_TYPES } from '../data/ProviderTypes';
import LocationListModal from './LocationListModal';
import { useState } from 'react';

const ProfileHeader = ({ name, image, licenses, areas, setShowRatingModal, type, providedRating, canReview, phNumber, eMail }) => {
  const [showLocationListModal, setShowLocationListModal] = useState(false);
  const onClose = () => setShowLocationListModal(false);
  return (
    <>
      <div className="flex flex-row justify-between text-gray-900">
        <div className="px-1 py-1 whitespace-nowrap text-gray-900 flex flex-row basis-[75%]">
          <div className="py-2 pr-3 basis-1/7">
            {image.length > 1
              ? <img src={image} className="rounded-full w-24 h-24 lg:block md:hidden hidden grow-0" alt={name} />
              : <img src={dummyImage} className="rounded-full w-24 h-24 lg:block md:hidden hidden grow-0" alt={name} />}
          </div>
          <ul className="flex flex-col basis-3/4">
            <li className={`${type === "assignment" ? "mt-1" : "mt-5"} font-bold text-xl flex flex-row gap-x-3`}><span>
              {name}
            </span>
              <ul>
                {licenses.map((license) => (
                  <li key={license.id}>
                    <Badge className="bg-shadowBlue text-navyBlue">{PROVIDER_TYPES[license.type]}</Badge>
                  </li>
                ))}
              </ul>
            </li>
            {type === "assignment" &&
              <li className='mt-4 text-sm flex flex-row gap-x-4 flex-wrap gap-y-2 font-semibold'>
                <div className='flex flex-row gap-x-2'>
                  <BsFillTelephoneFill className='w-3.5 h-3.5 mt-0.5' />
                  <span>{phNumber}</span></div>
                <div className='flex flex-row gap-x-2'>
                  <MdAttachEmail className='w-3.5 h-3.5 mt-1' />
                  <span>{eMail}</span></div>
              </li>}
            <li className='mt-4 text-xs flex flex-row gap-x-2'>
              <GrLocation className='w-3.5 h-3.5' />
              <div className='block lg:hidden md:hidden hover:cursor-grab hover:scale-125' onClick={() => setShowLocationListModal(true)}>{`${areas.length} ${areas.length > 1 ? "areas" : "area"}`}</div>
              {areas.slice(0, 3).map((area, index) => (
                <div key={index} className='hidden lg:block md:block'>{area.city.name}</div>))}
              {areas.length > 3 && <div className="hidden lg:block md:block hover:cursor-grab hover:scale-125" onClick={() => setShowLocationListModal(true)}>+{areas.length - 3}</div>}
              {/* <div className="hidden lg:block md:block hover:cursor-grab hover:scale-125" onClick={() => setShowLocationListModal(true)}>+7</div> */}
            </li>
            {type === "assignment" &&
              <li className="hidden tabletx:block">
                {!providedRating ? canReview && <div className="flex flex-row gap-x-2 h-fit mt-3"
                  onClick={() => setShowRatingModal(true)}>
                  <FaStar className="text-navyBlue mt-0.5 h-4 w-4 hover:text-yellow-700 hover:cursor-grab hover:scale-125" />
                  <span className="text-navyBlue hover:text-navyBlue text-sm hover:font-bold 
        hover:cursor-grab">Rate this assignment</span></div> :
                  <div className="flex flex-row gap-x-2 h-fit mt-3">
                    <FaStar className="mt-0.5 h-4 w-4 text-yellow-700" />
                    <span className="text-yellow-700 font-semibold text-sm">Rated</span></div>
                }
              </li>}
          </ul>
        </div>
        {type === 'assignment' && <>{!providedRating ? canReview &&
          <div className="flex flex-row gap-x-2 h-fit px-0
     mt-3 block tabletx:hidden" onClick={() => setShowRatingModal(true)}>
            <FaStar className="text-navyBlue mt-0.5 h-4 w-4 hover:text-yellow-700 hover:cursor-grab hover:scale-125" />
            <span className="text-navyBlue hover:text-navyBlue text-sm hover:font-bold 
        hover:cursor-grab">Rate this assignment</span></div> :
          <div className="flex flex-row gap-x-2 h-fit px-0  mt-3 block tabletx:hidden">
            <FaStar className="mt-0.5 h-4 w-4 text-yellow-700" />
            <span className="text-yellow-700 font-semibold text-sm">Rated</span></div>}</>
        }
      </div>
      {showLocationListModal && <LocationListModal areas={areas} title={"Service locations"} onClose={onClose} />}</>
  )
}

export default ProfileHeader