import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  listings: [],
  error: ''
}


// Generates pending, fulfilled and rejected action types
export const fetchListings = createAsyncThunk('listings/fetchListings', async ({pageNo=1, token}) => {
  return await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/listing/?page=${pageNo}`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  }).then((response) => response.data)
})

const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchListings.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchListings.fulfilled, (state, action) => {
      state.loading = false
      state.listings = action.payload
      state.error = ''
    })
    builder.addCase(fetchListings.rejected, (state, action) => {
      state.loading = false
      state.listings = []
      state.error = action.error.message
    })
  }
})

export default listingsSlice.reducer