import React from 'react'
import Button from './Button'
import Badge from './Badge'

const ModalWithPagination = (props) => {
  return (
    <div id="defaultModal" aria-hidden="true" className=" bg-opacity-25 backdrop-brightness-50 overflow-x-hidden flex items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full">
      <div className="relative p-4 w-full max-w-2xl max-h-full overflow-y-auto">
        <div className="relative bg-gray-50 rounded-lg shadow">
          <div className="flex justify-between items-start p-4 xs:py-2 rounded-t border-b ">
            <h3 className="text-xl xs:text-lg font-semibold text-gray-900 ">
              {props.title}
            </h3>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
                text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={() => props.onClose()}  >
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form onSubmit={props.onSubmit}>
            <div className='flex flex-row mt-3 px-20'>
              <Badge className="bg-navyBlue text-white font-bold">1</Badge>
              <div className="mt-3 bg-grey rounded-full w-full h-1">
                <div className={`bg-navyBlue h-1 rounded-full ${props.modalPage === 1 ? "w-1/2" : "w-full"}`}></div>
              </div>
              <Badge className={`${props.modalPage === 1 ? "bg-grey" : "bg-navyBlue"} text-white font-bold`}>2</Badge>
            </div>
            {props.children}
            <div className="flex items-center p-6 space-x-2 rounded-b border-t text-base xs:text-sm xs:py-3">
              <Button className="text-gray-500 bg-white hover:bg-gray-100 py-1.5 border border-gray-200  hover:text-gray-900"
                onClick={() => props.onClose()}>
                Cancel</Button>
              {props.modalPage === 1 && <Button className="py-1.5 ml-1"
                onClick={props.nextButtonClick}>Next</Button>}
              {props.modalPage === 2 && <><Button className="py-1.5 ml-1" onClick={() => props.setModalPage(1)}>Previous</Button>
                <Button className="py-1.5 ml-1" type="submit">{props.buttonName}</Button></>}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalWithPagination