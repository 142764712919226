import React from 'react'
import Badge from './Badge'

const Recurrence = ({ recurrence, day }) => {
  const isTrue = recurrence.includes(day.value)
  return (
      <Badge className={`${isTrue ? "bg-shadowBlue text-navyBlue" : "bg-badgeGrey border border-gray-300"} font-medium mt-1`}>{day.name}</Badge>
  )
}

export default Recurrence