import React from 'react'

const NotificationBadge = (props) => {
  return (
    <div className="relative">
      <div className={`absolute text-white font-bold inline-block  bottom-auto left-auto translate-x-2/4 -translate-y-1/2 
      rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-1 ${props.count < 1 ? "bg-transparent" : "bg-red-600"} rounded-full h-fit w-fit
      ${props.className && props.className.includes("text") ? "" : "text-sm"}
      ${props.className && props.className.includes("px") ? "" : "px-2.5"}
      ${props.position ? props.position : "top-1/2 right-9 "}
      ${props.className ? props.className : ''}`}
        onClick={props.onClick}>{props.count > 0 && props.count}{(props.type === "notification" && props.count > 9) && "+" }</div>
      {props.children}
    </div>
  )
}

export default NotificationBadge