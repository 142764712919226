import React from 'react'
import { useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import PopOver from './PopOver';
import Badge from './Badge';
import { ORIENTATION_TYPE } from '../data/OrientationType';
import { getProviderValue, getLabelFromValue, getCssFromValue } from "../util"

const OrientationTableRows = ({ orientationList, editOrientationButtonClick, setEditList, profiles }) => {
  const navigate = useNavigate();
  const handleEditClick = (event) => {
    event.stopPropagation();
    setEditList(orientationList);
    editOrientationButtonClick();
  }

  return (
    <>
      <tr className="border-b border-grey odd:bg-white even:bg-slate-50 hover:cursor-grab hover:bg-violet-100"
        onClick={() => navigate(`/orientations/${orientationList.id}`)}>
        <td className="px-6 py-4 whitespace-nowrap text-gray-900 w-max-1 font-bold text-sm">
          {orientationList.title.substring(0, 30)}{orientationList.title.length > 30 && "..."}
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          <Badge className={getCssFromValue(ORIENTATION_TYPE, orientationList.type)}>{getLabelFromValue(ORIENTATION_TYPE, orientationList.type)}</Badge>
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          <div className='flex flex-row gap-x-1'>
            {orientationList.licenses.map((license, index) => (
              <Badge className="bg-shadowBlue text-navyBlue" key={index}>{getProviderValue(profiles, license)}</Badge>))}</div>
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          {orientationList.is_active ? <Badge className="text-navyBlue bg-shadowBlue">Active</Badge> : <Badge className="text-black bg-badgeGrey">Inactive</Badge>}
        </td>
        <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
          <div className='flex space-x-3'>
            <PopOver message={"Edit"}><AiFillEdit className='hover:font-bold hover:cursor-grab text-gray-600 h-7 w-7 hover:scale-125'
              onClick={handleEditClick} /></PopOver>
          </div>
        </td>
      </tr>
    </>
  )
}

export default OrientationTableRows