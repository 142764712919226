import React, { useState } from "react";
import Modal from './Modal'
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import moment from "moment";
import BackdropLoading from "./BackdropLoading";

const AssignmentActionModal = ({ onClose, action, assignmentId, actionType, name }) => {
  const [notes, setNotes] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [endTime, setEndTime] = useState("");

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  let content = '';

  const updateAssignmet = useMutation(async (newStatus) => {
    return axios.put(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/${assignmentId}/`, newStatus, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'updateAssignment',
    onSuccess: () => {
      queryClient.invalidateQueries("assignment");
      queryClient.invalidateQueries("assignmentLogs");
      onClose();
      toast.success(<ToastNotification message="Action completed successfully" status="success" />);
    },
    onError: (error) => {
      if (error.response.data.note)
        toast.warn(<ToastNotification message={error.response.data.note[0]} status="warn" />);
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );

  const extendAssignmet = useMutation(async (newStatus) => {
    return axios.post(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment-extension/`, newStatus, {
      headers: {
        Authorization: `Bearer ${await token}`,
      }
    });
  }, {
    mutationKey: 'extendAssignment',
    onSuccess: () => {
      queryClient.invalidateQueries("assignment");
      queryClient.invalidateQueries("assignmentLogs");
      onClose();
      toast.success(<ToastNotification message={`Extension request sent to ${name} successfully`} status="success" />);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        if (typeof (error.response.data) === "object") {
          if (Object.keys(error.response.data).length > 0) {
            var key = Object.keys(error.response.data)[0];
            if (key.length > 0)
              toast.warn(<ToastNotification message={error.response.data[key][0]} status="warn" />);
            else
              toast.warn(<ToastNotification message={error.message} status="warn" />);
          }
          else
            toast.warn(<ToastNotification message={error.message} status="warn" />);
        }
        else
          toast.warn(<ToastNotification message={error.message} status="warn" />);
      }
      else
        toast.warn(<ToastNotification message={error.message} status="warn" />);
    },
  }
  );
  if (updateAssignmet.isLoading) {
    content = <BackdropLoading />
  }
  if (extendAssignmet.isLoading) {
    content = <BackdropLoading />
  }
  const notesChangeHandler = (event) => {
    setNotes(event.target.value);
  };
  const sheduleChangeHandler = () => {
    setIsChecked(!isChecked);
  };
  const endTimeChangeHandler = (event) => {
    setEndTime(event.target.value);
  };
  const onAssignmentActionSubmit = (event) => {
    event.preventDefault();
    if ((actionType === "Escalate" || actionType === "Cancel" || actionType === "End") && (notes.trim().length === 0 || notes.trim().length < 3)) {
      toast.warn(<ToastNotification message="Notes should have atleast 5 characters" status="warn" />);
      return;
    }
    if (actionType === "Extend" && endTime.trim().length === 0) {
      toast.warn(<ToastNotification message="Please enter valid end time" status="warn" />);
      return;
    }
    if (actionType === "Cancel") {
      updateAssignmet.mutate({
        action: action,
        note: notes,
        reschedule: isChecked
      })
    }
    else if (actionType === "Escalate" || actionType === "End") {
      updateAssignmet.mutate({
        action: action,
        note: notes
      })
    }
    else if (actionType === "Extend") {
      extendAssignmet.mutate({
        end: new Date(endTime),
        assignment_id: assignmentId
      })
    }
    else {
      updateAssignmet.mutate({
        action: action,
        note: null
      })
    }
  }
  return (<>
    <Modal title={`${actionType} Assignment`} buttonName={`Yes, ${actionType}`} onClose={onClose} onSubmit={onAssignmentActionSubmit}>
      <div className="flex flex-col p-6 gap-y-6">
        <span className="font-semibold">{`Are you sure you want to ${actionType.toLowerCase()} this assignment ?`}</span>
        {(actionType === "Escalate" || actionType === "Cancel" || actionType === "End") && <div>
          <label htmlFor="notes" className="block mb-2 text-sm font-medium text-gray-600">Reason</label>
          <textarea id="notes" rows="4" value={notes}
            onChange={notesChangeHandler}
            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Please provide an explanation" required></textarea></div>
        }
        {actionType === "Cancel" && <>
          {/* <div>
          <label htmlFor="notes" className="block mb-2 text-sm font-medium text-gray-600">Notes</label>
          <textarea id="notes" rows="4" value={notes}
            onChange={notesChangeHandler}
            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="notes" required></textarea></div> */}
          <div className="flex items-center flex-row space-x-3 mb-4">
            <input id="repeats" type="checkbox" value="" checked={isChecked}
              onChange={sheduleChangeHandler}
              className="w-4 h-4 text-navyBlue bg-gray-100 rounded border-gray-300 focus:ring-navyBlue" />
            <label htmlFor="repeats" className="ml-2 text-sm font-medium text-gray-600">Create new listing for this assignment?</label>
          </div> </>}
        {actionType === "Extend" && <div>
          <label htmlFor="endtTime" className="block mb-2 text-sm font-medium text-gray-600">New End time</label>
          <input type="datetime-local" id="endTime" min={moment(new Date()).format('yyyy-MM-DDTHH:mm')} value={endTime}
            onChange={endTimeChangeHandler}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="New time please." required /></div>
        }
      </div>
    </Modal> {content}</>
  )
}

export default AssignmentActionModal