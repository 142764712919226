import React from 'react'
import Modal from './Modal'

const LocationListModal = ({ title, areas, onClose }) => {
  return (
    <Modal title={title} width={"max-w-md"} onClose={onClose} hideSubmitButton={true}>
      <div className='p-3'>
        {areas.map((area, index) => (
          <div
            key={index}
            className={`text-sm py-1`} >
            {area.city.name}
          </div>
        ))}</div>
    </Modal>
  )
}

export default LocationListModal