import dayjs from "dayjs";
import React, { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

export default function CalendarHeader() {
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }
  return (
    <header className="py-2 flex items-center w-full">
      {/* <div className="flex flex-row justify-between">
      <div className="flex flex-row"> */}
      {/* <h1 className="lg:mr-10 md:mr-10 mr-5 lg:text-xl md:text-xl text-lg text-gray-700 fond-bold">
        Calendar
      </h1> */}
      <button
        onClick={handleReset}
        className="border rounded border-navyBlue py-2 px-4 mr-5 text-gray-600 font-semibold">
        Today
      </button>
      <div className="flex flex-row gap-x-2">
        <FaChevronLeft className="cursor-pointer text-gray-700" onClick={handlePrevMonth} />
        <FaChevronRight className="cursor-pointer text-gray-700" onClick={handleNextMonth} />
      </div>
      <h2 className="ml-4 lg:text-xl md:text-xl text-lg text-gray-600 font-bold flex flex-none block xs:hidden">
        {dayjs(new Date(dayjs().year(), monthIndex)).format(
          "MMMM YYYY"
        )}
      </h2>
      <h2 className="ml-4 lg:text-xl md:text-xl text-lg text-gray-600 font-bold flex flex-none xs:block hidden">
        {dayjs(new Date(dayjs().year(), monthIndex)).format(
          "MMM YYYY"
        )}
      </h2>
    </header>
  );
}

