import React from 'react'
import moment from 'moment'
import PopOver from './PopOver'

const Timeline = ({ logs }) => {
  return (
    <ol className="relative border-l border-navyBlue ">
      {logs.map((log, index) => (
        <li className={`${index === logs.length - 1 ? 'mb-2' : 'mb-10'} ml-4`} key={index}>
          <div className="absolute w-3 h-3 bg-navyBlue rounded-full mt-1.5 -left-1.5 border border-white "></div>
          <time className="mb-1 text-sm font-normal leading-none text-navyBlue">{moment(log.date).format('MMMM Do YYYY, hh:mm a')}</time>
          <div className='flex flex-row gap-x-5'>
            <h3 className="text-base font-semibold text-gray-900">{log.message}</h3>
            {log.note && <PopOver message={log.note}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                <path d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              </PopOver>}
          </div>
        </li>))}
    </ol>
  )
}

export default Timeline