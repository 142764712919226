import React from 'react'
import Table from './Table';
import TableHead from './TableHead';
import InterestTableRows from './InterestTableRows';
import AssignmentTableRows from './AssignmentTableRows';
import BillingTableRows from './BillingTableRows';
import OrientationTableRows from './OrientationTableRows';

const InterestTable = (props) => {
  // const orderedListing = props.listing.slice().sort((a, b) => b.updated_on.localeCompare(a.updated_on))
  return (
    <Table
      page={props.page}
      pagesArray={props.pagesArray}
      count={props.count}
      setPage={props.setPage}
      previousPage={props.previousPage}
      nextPage={props.nextPage}
      totalCount={props.totalCount}
    >
      <TableHead
        tableHead={props.tableHead}
        setOrderBy={props.setOrderBy}
        setDirection={props.setDirection}
        orderBy={props.orderBy}
      />
      <tbody className="text-sm">
        {props.listing.map((list) => (
          props.tableValue === "interests" ? (<InterestTableRows interestList={list}
            key={list.id}
          />) : props.tableValue === "billing" ? (<BillingTableRows billingList={list}
            setInvoiceLoading={props.setInvoiceLoading}
            key={list.id}
          />) : props.tableValue === "orientation" ? (<OrientationTableRows orientationList={list}
            editOrientationButtonClick={props.editOrientationButtonClick}
            setEditList={props.setEditList}
            profiles={props.profiles}
            key={list.id}
          />) : (<AssignmentTableRows assignmentList={list}
            key={list.id}
          />)
        ))}
      </tbody>
      {/* : props.tableValue === "billing" ?
        <tbody className="text-sm">
          {props.listing.map((billingList) => (
            <BillingTableRows billingList={billingList}
              key={billingList.id}
            />
          ))}
        </tbody>
        : <tbody className="text-sm">
          {props.listing.map((assignmentList) => (
            <AssignmentTableRows assignmentList={assignmentList}
              key={assignmentList.id}
            />
          ))}
        </tbody>
      } */}
    </Table>
  )
}

export default InterestTable