import React from 'react'
import useOrientations from '../api/hooks/useOrientations'
import useProfileTypes from '../api/hooks/useProfileTypes'
import CreateOrientationModal from '../components/CreateOrientationModal'
import TopBar from '../components/TopBar'
import LoadingPulse from '../components/LoadingPulse';
import ErrorMessage from '../components/ErrorMessage';
import EmptyList from '../components/EmptyList';
import Title from '../components/Title';
import Layout from '../components/Layout';
import InterestTable from '../components/InterestTable';
import { useState } from 'react'
import Button from '../components/Button';

const Orientations = () => {
  const [page, setPage] = useState(1)
  const [viewModal, setViewModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editList, setEditList] = useState({})
  const [orderBy, setOrderBy] = useState("created_on")
  const [direction, setDirection] = useState(false)
  const {
    isLoading,
    isError,
    error,
    data: orientations,
  } = useOrientations(page, orderBy, direction);
  const {
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
    data: profiles,
  } = useProfileTypes();

  const tableHead = [{ name: 'TITLE', value: "title", isSorting: false },
  { name: 'TYPE', value: "type", isSorting: true },
  { name: 'PROVIDER TYPE', value: "liscences", isSorting: false },
  { name: 'STATUS', value: "is_active", isSorting: true },
  { name: 'ACTIONS', value: "actions", isSorting: false }]
  let content = '';
  if (isLoading || isLoadingProfile) {
    content = <LoadingPulse />
  }
  if (isError) {
    content = <ErrorMessage errorMessage={error.message} />
  }
  if (isErrorProfile) {
    content = <ErrorMessage errorMessage={errorProfile.message} />
  }
  if (orientations && profiles) {
    const totalPages = Math.ceil(orientations.count / orientations.page_size);
    const previousPage = () => setPage(page - 1);
    const onClose = () => setViewModal(false);
    const nextPage = () => setPage(page + 1);
    const editOrientationButtonClick = () => {
      setViewModal(true);
      setIsEditMode(true);
    }
    const createOrientationButtonClick = () => {
      setViewModal(true);
      setIsEditMode(false);
      setEditList({});
    }
    const pagesArray = Array(totalPages).fill().map((_, index) => index + 1);
    content = <>
      <div className={`flex flex-row lg:flex-row md:flex-row flex-col gap-y-2 ${orientations.count < 1 ? "lg:justify-end md:justify-end" : "lg:justify-between md:justify-between"}`}>
        {orientations.count >= 1 && <Title title={"Orientations"} />}
        <Button className={`mb-2 px-4 py-1.5 ${orientations.count > 0 ? "lg:mt-3 md:mt-3" : "mt-4"}`} onClick={createOrientationButtonClick}>Create Orientation</Button>
      </div>
      {orientations.count >= 1 ? <InterestTable
        listing={orientations.results}
        tableHead={tableHead}
        pagesArray={pagesArray}
        page={page}
        count={orientations.page_size}
        setPage={setPage}
        previousPage={previousPage}
        nextPage={nextPage}
        editOrientationButtonClick={editOrientationButtonClick}
        setEditList={setEditList}
        totalCount={orientations.count}
        setOrderBy={setOrderBy}
        setDirection={setDirection}
        orderBy={orderBy}
        tableValue="orientation"
        profiles={profiles}
      /> :
        <EmptyList message="No orientations created!" />}
      {viewModal && <CreateOrientationModal
        onClose={onClose}
        isEditMode={isEditMode}
        editList={editList}
        profiles={profiles}
      />}
    </>
  }

  return (
    <div>
      <Layout>
        <TopBar />
        {content}
      </Layout>
    </div>
  );
}

export default Orientations