import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getAssignmentsByDate = async (token, startDate, endDate) => {
  const { data } = await axios.get(`${process.env.REACT_APP_KARER_API_HOST}/api/v1/booking/assignment/?expand=profile&page_size=100&date_from=${startDate}&date_to=${endDate}&order_by=start&direction=asc`, {
    headers: {
      Authorization: `Bearer ${await token}`,
    },
  });
  return data;
};

export default function useAssignmentsByDate(startDate, endDate) {
  const { getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();
  return useQuery(['assignmentsByDate', startDate, endDate], () => getAssignmentsByDate(token, startDate, endDate));
}