import { BsFolder } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { MdAutoStories } from "react-icons/md";
import SideBarItems from "./SideBarItems";
import Logout from "./buttons/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useState, useEffect } from 'react'
import logo from '../logo.png'

const SideBar = () => {
  const [permissions, setPermissions] = useState([]);
  const { getAccessTokenSilently, isLoading } = useAuth0();
  useEffect(() => {
    const getPermissions = async () => {
      const token = getAccessTokenSilently();
      setPermissions(jwt_decode(await token).permissions);
    };
    if (!isLoading) {
      getPermissions();
    }
  }, [isLoading, getAccessTokenSilently]);
  const Menus = [
    // {
    //   title: "Dashboard", href: "/", icon: <BiCategory className="h-6 w-6" />, allowedRoles: ["list:listing", "list:interest", "list:assignment", "list:invoice"]
    // },
    {
      title: "Assignments ", href: "/assignments", icon: <MdOutlineAssignmentInd className="h-6 w-6" />, allowedRoles: ["list:assignment", "detail:assignment", "detail:assignment-log"]
    },
    {
      title: "Listings", href: "/listings", icon: <BsFolder className="h-6 w-6" />, allowedRoles: ["list:listing", "create:listing", "update:listing", "detail:listing"]
    },
    {
      title: "Interests", href: "/interests", icon: <BsPeople className="h-6 w-6" />, allowedRoles: ["list:interest", "detail:interest"]
    },

    {
      title: "Orientations", href: "/orientations", icon: <MdAutoStories className="h-6 w-6" />, allowedRoles: ["list:listing", "list:interest", "list:assignment", "list:invoice"]
    },
    {
      title: "Billing", href: "/billing", icon: <AiFillDollarCircle className="h-6 w-6" />, allowedRoles: ["list:invoice", "detail:invoice"]
    },
  ];
  const { user, logout } = useAuth0()
  const navigate = useNavigate();
  return (
    // <aside className="lg:w-64 w-16 xs:w-12 h-screen" aria-label="Sidebar">
    // <aside className="w-64 tabletSidebar:w-[185px] tabletSidebar_md:w-[185px] tabletSidebar_sm:w-16 tabletSidebar_lg:w-16 xs:w-12 h-screen" aria-label="Sidebar">
    <aside className="w-64 tabletSidebar:w-[185px]  tabletSidebar_sm:w-16 tabletSidebar_lg:w-16 xs:w-12 h-screen" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 xs:px-2  bg-black h-screen flex flex-col justify-between gap-y-12">
        <section>
          <div className="flex items-center mb-5 pl-1">
            <img src="https://karer-prod.nyc3.digitaloceanspaces.com/common/images/logo_extend.png" alt="" className="w-32 h-12 block tabletSidebar_lg:hidden tabletSidebar_sm:hidden xs:hidden" />
            <img src={logo} alt="" className="hidden xs:block xs:h-8 tabletSidebar_lg:block tabletSidebar_sm:block" />
          </div>
          <ul className="space-y-2 xs:space-y-3">
            {Menus.map((Menu, index) => (
              <li key={index}>
                {permissions.some(item => Menu.allowedRoles.includes(item)) &&
                  <SideBarItems title={Menu.title} href={Menu.href} icon={Menu.icon} />}
              </li>))}
          </ul>
        </section>
        <section>
          <div className="flex items-center  tabletSidebar:p-2 tabletSidebar_md:p-2 tabletSidebar_lg:p-0 tabletSidebar_sm:p-0 xs:p-0 p-2  space-x-4 justify-self-end">
            <img src={user.picture} alt="" className="tabletSidebar:w-9 tabletSidebar:h-9 tabletSidebar_lg:h-9 tabletSidebar_lg:w-9 
            w-12 h-12 tabletSidebar_sm:h-9 tabletSidebar_sm:w-9 tabletSidebar_md:w-9 tabletSidebar_md:h-9
            xs:h-9 xs:w-9 rounded-lg hover:cursor-grab"
              onClick={() => navigate("/organiser")} />
            <div className="block tabletSidebar:block tabletSidebar_md:block tabletSidebar_lg:hidden tabletSidebar_sm:hidden xs:hidden overflow-x-hidden">
              <h2 className="text-white font-semibold truncate">{user.name}</h2>
              <span className="flex items-center space-x-1">
                <Link to="/organiser" className="text-xs hover:underline text-white">View profile</Link>
              </span>
            </div>
          </div>
          <li className="block tabletSidebar:block tabletSidebar_md:block tabletSidebar_lg:hidden tabletSidebar_sm:hidden  xs:hidden"><Logout /></li>
          <li className="hidden tabletSidebar:hidden tabletSidebar_md:hidden tabletSidebar_lg:block tabletSidebar_sm:block mt-6 xs:block"><BiLogOutCircle className="h-7 w-7 text-white hover:scale-125"
            onClick={() => logout({ returnTo: window.location.origin })} /></li>
        </section>
      </div>
    </aside>
  );
};

export default SideBar;

