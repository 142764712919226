import React from 'react'
import Card from './Card'
import { ORIENTATION_TYPE } from '../data/OrientationType'
import { getCssFromValue, getLabelFromValue } from "../util"
import Badge from './Badge'
import parse from "html-react-parser";

const OrientationDetailCard = ({ orientation }) => {
  return (
    <Card>
      <div className='flex flex-row gap-x-3'>
        <h2 className="text-xl xs:text-lg font-bold break-words">{orientation.title}</h2>
        <Badge className={getCssFromValue(ORIENTATION_TYPE, orientation.type)}>
          <span>{getLabelFromValue(ORIENTATION_TYPE, orientation.type)}</span>
        </Badge>
      </div>
      <div className='border mt-5'></div>
      <h2 className='font-bold text-base py-4'>Description</h2>
      <span className="no-tailwindcss-base">
        {parse(orientation.content)}
      </span>
    </Card>)
}

export default OrientationDetailCard