import { RRule } from 'rrule'

export const WEEKDAYS = 
[
   { id:0, value: RRule.MO, label: 'Mon', checked: false },
   { id:1, value: RRule.TU, label: 'Tue', checked: false },
   { id:2, value: RRule.WE, label: 'Wed', checked: false },
   { id:3, value: RRule.TH, label: 'Thu', checked: false },
   { id:4, value: RRule.FR, label: 'Fri', checked: false },
   { id:5, value: RRule.SA, label: 'Sat', checked: false },
   { id:6, value: RRule.SU, label: 'Sun', checked: false },
 ];
// {
//  0: { value: RRule.MO, label: 'Monday' },
//   1: { value: RRule.TU, label: 'Tuesday' },
//   2: { value: RRule.WE, label: 'Wednesday' },
//    3: { value: RRule.TH, label: 'Thursday' },
//    4: { value: RRule.FR, label: 'Friday' },
//    5: { value: RRule.SA, label: 'Saturday' },
//    6: { value: RRule.SU, label: 'Sunday' },
// }