import React from 'react'

const Badge = (props) => {
  return (
    <span className={`text-xs px-2.5 py-2 inline-block leading-none text-center
     whitespace-nowrap align-baseline  h-fit w-fit rounded-full 
     ${props.className ? props.className : 'text-black bg-badgeGrey'}
     ${props.className && props.className.includes("font-") ? "" : "font-semibold"}
     `} onClick={props.onClick}>
      {props.children}</span>
  )
}

export default Badge