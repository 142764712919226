import React from 'react'
import TablePagination from './TablePagination'
import PaginationData from './PaginationData'

const Table = (props) => {
  return (
    <>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-x-auto">
            <table className="min-w-full border border-grey">
              {props.children}
            </table>
          </div>
        </div>
      </div>
      <PaginationData
      count={props.count}
      totalCount={props.totalCount}
      pagesArray={props.pagesArray}
      page={props.page}
      />
      { props.pagesArray.length >1 && <TablePagination
        page={props.page}
        pagesArray={props.pagesArray}
        setPage={props.setPage}
        previousPage={props.previousPage}
        nextPage={props.nextPage} 
      /> }
    </>
  )
}

export default Table