import React from "react";
import CalendarDay from "./CalendarDay";
export default function CalendarMonth({ month, assignments }) {
  return (
    <div className="flex-none grid grid-cols-7 grid-rows-6 auto-rows-fr w-full min-w-[900px]">
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <CalendarDay
              day={day}
              key={idx}
              rowIdx={i}
              assignments={assignments} />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
