import React, { useState } from 'react'
import Breadcrumb from './Breadcrumb'
import Drawer from './Drawer'
import { MdNotifications } from "react-icons/md";
import NotificationBadge from './NotificationBadge';
import useNotification from '../api/hooks/useNotification';
import ToastNotification from '../components/ToastNotification';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import moment from 'moment';

const TopBar = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationPage, setNotificationPage] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMoreClicked, setHasMoreClicked] = useState(false);
  const {
    isError,
    error,
    data: notifications } = useNotification(notificationPage, "created_on", false);
  useEffect(() => {
    if (isError && error.request.status !== 403) {
      toast.warn(<ToastNotification message={`Couldn't show notifications: ${error.message}`} status="warn" />);
    }
  }, [isError, error]);
  useEffect(() => {
    if (notifications) {
      if (notificationPage === 1)
        setItems(notifications.results)
      else
        if (hasMoreClicked) {
          setItems(prevItems => [...prevItems, ...notifications.results]);
          setHasMoreClicked(false)
        }
    }
  }, [notifications, notificationPage, hasMoreClicked]);
  const handleNotificationClick = () => {
    setShowNotification(true)
  }
  let content = '';
  if (notifications) {
    const unReadMessages = notifications.results.filter(notification => moment(localStorage.getItem("time")).isBefore(notification.created_on));
    content = <>
      <NotificationBadge className="text-xs px-2 xs:px-1.5 xs:py-0.5 hover:cursor-grab"
        onClick={handleNotificationClick}
        type={"notification"}
        count={unReadMessages.length} position="top-1 xs:right-3 right-1">
        <MdNotifications className='h-8 w-8 xs:h-7 xs:w-7 text-navyBlue hover:cursor-grab xs:mr-3' onClick={handleNotificationClick} />
      </NotificationBadge>
      <Drawer
        setShowNotification={setShowNotification}
        showNotification={showNotification}
        setNotificationPage={setNotificationPage}
        items={items}
        hasMore={notifications.next}
        setItems={setItems}
        setHasMoreClicked={setHasMoreClicked}
        unReadMessages={unReadMessages}
        notificationPage={notificationPage} />
    </>
  }
  return (<>
    <div className='flex lg:flex-row lg:justify-between md:justify-end justify-end'>
      <Breadcrumb className="lg:block md:hidden hidden" />
      {content}
    </div>
    <div className='border mt-4 xs:mt-3'></div>
  </>
  )
}

export default TopBar