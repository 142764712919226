import React from 'react'
import Button from './Button'
import withPermissionRequired from "../services/auth0";

const EditListButton = ({ editBookingButtonClick }) => {
  return (
    <Button className="border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white py-1.5 "
      onClick={editBookingButtonClick}>Edit</Button>)
}

export default withPermissionRequired(EditListButton, [ "update:listing"], false)