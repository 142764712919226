import React from 'react'

const Button = (props) => {
  return (
    <button
      className={`px-3 border-2 font-medium tracking-wide transition duration-200 
      rounded-lg ${props.textSize && props.textSize}
       ${props.className ? props.className : ''}
       ${props.className && props.className.includes("bg") ? "" : "bg-deep-purple-accent-400"}
       ${props.className && props.className.includes("hover:bg") ? "" : "hover:bg-deep-purple-accent-700"}
       ${props.className && props.className.includes("text") ? "" : "text-white"}
       `}
      type={props.type || 'button'}
      onClick={props.onClick} >
      {props.children}
    </button>
  )
}

export default Button