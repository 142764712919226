import React from 'react'
import { Editor } from "@tinymce/tinymce-react";

const TinyMceEditor = ({ height, description, setDescription }) => {
  return (
    <Editor
      apiKey='eujvrdcbi0x760qo3ugyiue7824i2alauqg8tiglmjyga08w'
      init={{
        theme: "silver",
        height: height,
        menubar: false,
        plugins: 'advlist autolink lists link',
        toolbar: 'undo redo | formatselect | ' +
          'bold italic underline link | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
      value={description}
      onEditorChange={(newValue) => {
        setDescription(newValue);
      }}
    />
  )
}

export default TinyMceEditor